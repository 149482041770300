

import React, {  } from 'react';
import { Box, Grid, Card, CardContent } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import errorImg from "../img/error.png";

 interface IErrorProps{

    children?:React.ReactNode;
}

export const Error: React.FC<IErrorProps> = ()=>{
    const classes = useStyles({});
    return (
        <Grid className={classes.root} item xs={12} style={{ display: "flex" }}>
            <Card raised={true}>
            <CardContent className={classes.content}>
                <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
                <h3>Ooops, něco se pokazilo</h3>
                <img src={errorImg} alt="chyba nacitani" height={250}/>
                <div>
                    Omlouváme se, Nepodařilo se nám načíst aktuální data. Zkuste to prosím později znovu. Pokud problém přetrvává neváhejte nás kontaktovat na <a href="mailto:info@zabavnamesta.cz">info@zabavnamesta.cz</a>.
                </div>
                </Box>
            </CardContent>
            </Card>
        </Grid>

)}

const useStyles = makeStyles(() => ({
    root: {

    },
    content:{
        color:"red"
    },
}));