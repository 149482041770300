import React from "react";
interface IconProps{
    fill:string;
    class?:string;
    className?: string;
    height:number;
    hoverFill?:string;
}


const ContestIcon = (props:IconProps) =>(
    <svg fill={props.fill} className={props.class} viewBox="0 0 297.361 297.361" width={props.height} height={props.height} >
     <path d="M272.181,17.647h-45.429V7.5c0-4.143-3.357-7.5-7.5-7.5H78.111c-4.143,0-7.5,3.357-7.5,7.5v10.147H25.181
		c-4.143,0-7.5,3.358-7.5,7.5v52.945c0,0.612,0.084,15.174,7.408,29.824c6.481,12.964,19.764,28.229,46.565,30.356
		c4.693,28.639,25.012,52.07,51.885,61.242v47.549H95.75c-3.229,0-6.096,2.066-7.116,5.13l-11.756,35.297
		c-0.761,2.287-0.377,4.801,1.032,6.756c1.41,1.956,3.674,3.114,6.084,3.114l129.373-0.002c2.411,0,4.674-1.159,6.084-3.114
		c1.409-1.955,1.793-4.469,1.031-6.756l-11.757-35.295c-1.021-3.064-3.887-5.13-7.115-5.13h-27.785v-47.55
		c26.873-9.172,47.191-32.604,51.884-61.241c26.801-2.127,40.084-17.393,46.564-30.356c7.323-14.65,7.407-29.212,7.407-29.824
		V25.147C279.681,21.005,276.323,17.647,272.181,17.647z M38.718,101.63c-5.896-11.508-6.036-23.44-6.037-23.537V32.647h37.931
		v90.439C55.842,121.32,45.124,114.135,38.718,101.63z M202.964,282.359l-108.566,0.002l6.76-20.297h95.046L202.964,282.359z
		 M158.825,247.064h-20.286v-44.051c3.322,0.434,6.703,0.681,10.142,0.681c3.439,0,6.821-0.247,10.145-0.681V247.064z
		 M148.681,188.694c-34.776,0-63.069-28.303-63.069-63.092V15h126.141v110.603C211.752,160.392,183.458,188.694,148.681,188.694z
		 M264.681,78.07c-0.001,0.119-0.142,12.052-6.037,23.56c-6.405,12.505-17.122,19.69-31.892,21.457V32.647h37.929V78.07z"/>
	<path d="M189.224,71.369l-21.617-6.371l-12.739-18.594c-1.398-2.041-3.714-3.261-6.188-3.261c-2.475,0-4.789,1.221-6.188,3.262
		l-12.737,18.593l-21.617,6.371c-2.373,0.699-4.248,2.523-5.013,4.876c-0.765,2.353-0.32,4.931,1.189,6.892l13.744,17.867
		l-0.621,22.535c-0.068,2.474,1.088,4.821,3.089,6.275c2.002,1.453,4.59,1.827,6.923,0.998l21.231-7.556l21.234,7.556
		c0.818,0.291,1.669,0.434,2.514,0.434c1.563,0,3.11-0.488,4.409-1.432c2.001-1.454,3.157-3.802,3.089-6.275l-0.621-22.535
		l13.744-17.868c1.508-1.96,1.952-4.538,1.188-6.891C193.472,73.893,191.597,72.068,189.224,71.369z M165.789,93.973
		c-1.051,1.367-1.6,3.055-1.552,4.779l0.393,14.259l-13.436-4.781c-0.814-0.289-1.664-0.434-2.515-0.434
		c-0.851,0-1.701,0.145-2.515,0.434l-13.433,4.78l0.393-14.258c0.048-1.725-0.501-3.413-1.553-4.78l-8.698-11.308l13.681-4.032
		c1.655-0.488,3.092-1.532,4.067-2.956l8.058-11.763l8.06,11.764c0.976,1.423,2.412,2.467,4.067,2.955l13.681,4.032L165.789,93.973z
		"/>

    </svg>
    )

    export default ContestIcon;