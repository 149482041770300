

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../theme";

interface IIconItemProps {

    icon?: React.ReactNode;
    title: string;
    children?: React.ReactNode;
}

export const IconColumn: React.FC<IIconItemProps> = ({ title, icon, children }) => {
    const classes = useStyles({});

    return (
        <div className={classes.root}>
            <div className={classes.icon}>
                {icon}
            </div>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}
const size = 50;
const useStyles = makeStyles(() => ({
    root: {
        fontSize: "1em",
        padding: "10px 10px",
        display:"flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign:"justify",

    },
    icon:{
        height: size,
        width: size,
        /*background: colors.game.icon.bgColor,
        color: colors.game.icon.color,
        borderRadius: "50%",
        */
        flexShrink: 0,
        display: "flex",
        alignItems:"center",
        justifyContent: "center",
        marginBottom: 10,
        transition: "fill 500ms linear",
        "& svg:hover":{
            fill: colors.bullet.icon.hoverColor,
            transition: "fill 500ms linear",
        }

    },
    content:{
        padding: "0px 15px",
    },
    title:{
        textAlign: "center",
        fontFamily: "SourceSansPro-light",
        fontSize: "16px",
        fontWeight: 300,
        textTransform: "uppercase",
        lineHeight: "26px",
        letterSpacing: 3,
     
    }

}));