

import React, { useState, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { config } from "../config";
import { Review, ReviewItem } from "./Review";


import axios from "axios";
import { Error } from "./Error";
import { AnimationType, AnimatedMount } from './AnimatedMount';


interface IReviewsProps {
    children?: React.ReactNode;
}

export const Reviews: React.FC<IReviewsProps> = () => {
    const classes = useStyles({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [reviews, setReviews] = useState<ReviewItem[] | null>(null);
    const [, setError] = useState<string | null>(null);

    const loadData = async () => {
        try {
            const result = await axios.get(`${config.reviewsUrl}`);
            if (result?.data?.length) {
                //console.log("Obtained data", result.data);
                setReviews(result.data);
            }
            else {
                console.error("Failed to obtain reviews offer", result);
                setError("Nepodařilo se načíst data");
            }
        }
        catch (e) {
            console.error("Failed to obtain reviews offer");
            setError("Nepodařilo se načíst data");
        }
        finally {
            setIsLoading(false);

        }
    }

    useEffect(() => {
        loadData();
        return () => {
            setReviews(null);
        };

    }, []);

    const getAnimation = (index: number) => {
        if (index % 3 === 0) return AnimationType.slideInLeft;
        else if (index % 3 === 1) return AnimationType.slideInUp;
        else return AnimationType.slideInRight;
    };

    const renderError = () => {
        return (<Error />);
    }
    const renderContent = () => {
        return (
            <Grid container alignItems="stretch">
                {reviews!.filter((i, idx) => idx < 3).map((r, index) =>
                    <Grid className={classes.game} item xs={12} sm={6} md={4} style={{ display: "flex" }} key={index}>
                        <AnimatedMount onEnterAnimation={getAnimation(index)} duration={"1.3s"} display="flex">
                        <Review isLoading={isLoading || !r} title={r.Name} subtitle={r.GameName} rating={r.TotalRating} description={r.TextContent} />
                        </AnimatedMount>
                    </Grid>
                )}
            </Grid>

        );
    }
    const renderSkeletons = () => {
        return (
            <Grid container alignItems="stretch">
                <Grid className={classes.game} item xs={12} sm={6} md={4} style={{ display: "flex" }}>
                    <Review isLoading={isLoading} />
                </Grid>
                <Grid className={classes.game} item xs={12} sm={6} md={4} style={{ display: "flex" }}>
                    <Review isLoading={isLoading} />
                </Grid>

                <Grid className={classes.game} item xs={12} sm={6} md={4} style={{ display: "flex" }}>
                    <Review isLoading={isLoading} />
                </Grid>
            </Grid>
        );

    }
    const render = () => {
        if (isLoading)
            return renderSkeletons();
        else if (!isLoading && reviews?.length)
            return renderContent();
        else
            return renderError();
    }

    return (
        <React.Fragment>
            {render()}
        </React.Fragment>
    );

}


const useStyles = makeStyles(theme => ({
    root: {

    },
    game: {
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },

}));