

import React from 'react';
import { colors } from "../theme";
import { CircleIcon } from './CircleIcon';
import CardioIcon from "../img/CardioIcon";
import {DifficultyLevel} from "./Game";
interface ILevelIndicatorProps {
    level: DifficultyLevel;
    circleSize?: number;
    iconSize?: number;
}

interface ILevelColor{
    bgColor: string;
    color: string;
    hoverColor: string;
}
export const LevelIndicator: React.FC<ILevelIndicatorProps> = ({ level, circleSize = 28, iconSize = 16 }) => {
    const getLvlColors = (level: DifficultyLevel)=>{
        switch (level){
            case DifficultyLevel.hard: return colors.game.level.hard as ILevelColor;
            case DifficultyLevel.medium: return colors.game.level.medium as ILevelColor;
            case DifficultyLevel.easy: 
            default:
            return colors.game.level.easy as ILevelColor;
        }
    }
    const getTooltip = (level: DifficultyLevel)=>{
        switch (level){
            case DifficultyLevel.hard: return "Těžká obtížnost"
            case DifficultyLevel.medium: return "Střední obtížnost"
            case DifficultyLevel.easy: 
            default:
            return "Lehká obtížnost"
        }
    }
    const lvlColors = getLvlColors(level);
    return (
        <CircleIcon size={circleSize} title={getTooltip(level)} bgColor={lvlColors.bgColor} hoverColor={lvlColors.hoverColor} >
            <CardioIcon fill={lvlColors.color} height={iconSize} />
        </CircleIcon>
    )
}
