import React, { Fragment } from "react";
import { CityType } from "src/types";
import { Content, IContentProps } from "./Content";

interface ICityContentProps extends IContentProps {
  city?: CityType;
}

export const CityContent: React.FC<ICityContentProps> = (props) => {
  const restProps: IContentProps = props;
  return <Fragment>{props.city && <Content {...restProps}></Content>}</Fragment>;
};
