import React from "react";
interface IconProps{
    fill:string;
    class?:string;
    className?: string;
    height:number;
}


const CardioIcon = (props:IconProps) =>(
    <svg fill={props.fill} className={props.class} viewBox="0 0 512 512" width={props.height} height={props.height} >
        <path d="m171.41 262.572 34.976-66.287c2.857-5.415 8.72-8.55 14.811-7.92 6.09.63 11.187 4.899 12.875 10.785l32.087 111.873 28.583-41.902c2.794-4.097 7.433-6.547 12.392-6.547h185.67c12.436-22.524 19.196-48.058 19.196-74.192 0-84.566-68.8-153.365-153.365-153.365-37.996 0-74.61 14.206-102.635 39.417-28.024-25.211-64.639-39.417-102.635-39.417-84.565 0-153.365 68.799-153.365 153.365 0 26.135 6.76 51.668 19.197 74.19z"/><path d="m315.059 292.572-42.119 61.747c-2.821 4.136-7.483 6.547-12.389 6.548-.792 0-1.59-.063-2.388-.191-5.741-.924-10.431-5.083-12.034-10.673l-30.613-106.733-21.793 41.304c-2.597 4.92-7.703 8-13.267 8h-139.622c2.718 2.94 5.558 5.781 8.518 8.514l178.656 164.952c7.644 7.057 17.585 10.943 27.992 10.943 10.913 0 21.179-4.207 28.946-11.854l177.702-164.043c2.96-2.733 5.8-5.574 8.518-8.514z"/>
    </svg>
    )

    export default CardioIcon;