

import React from 'react';
import { Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

export interface IContentProps {

    children?: React.ReactNode;
    background: string;
}

export const Content: React.FC<IContentProps> = ({ background, children }) => {
    const classes = useStyles({});

    return (
        <div className={classes.root} style={{ backgroundColor: background }}>
            <Container>
                {children}
            </Container>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        fontSize: "1.4em",
        padding: "30px 0px",
        fontFamily: "SourceSansPro-light",

        cursor: "default",
        "& h4": {
            marginBottom: 5,
        },
        "& a": {
            color: "#666",

        },
        "& h2": {
            textAlign: "center",
            fontFamily: "SourceSansPro",
            fontSize: "36px",
            fontWeight: 300,
            textTransform: "uppercase",
            lineHeight: "50px",
            letterSpacing: 5,
        },
        "& h3": {
            textAlign: "center",
            fontFamily: "SourceSansPro-light",
            fontSize: "22px",
            fontWeight: 300,
            textTransform: "uppercase",
            lineHeight: "26px",
            letterSpacing: 4,
        },
        "& ul h4": {
            fontFamily: "SourceSansPro",
            fontSize: "16px",
            fontWeight: 300,
            textTransform: "uppercase",
            lineHeight: "18px",
            letterSpacing: 2,
            textDecoration: "underline",
        }



    },


}));