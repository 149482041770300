import { Grid } from '@material-ui/core';
import React from 'react';
import { BulletItem } from './layout/BulletItem';
import { Content } from './layout/Content';
import ResponsiveOrderedList from './layout/ResponsiveOrderedList';
import { Review, ReviewItem } from './layout/Review';
import { CompanyReviewItem, CompanyReviews } from './reviews/company/CompanyReviews';
import { colors } from "./theme";
import { Helmet } from 'react-helmet';

const imgBraun = require("./reviews/company/logo/braun.png");
const imgSiemens = require("./reviews/company/logo/siemens.png");

const reviews: CompanyReviewItem[] = [
    { name: "braun", author: "Magda Stehlíková", description: "Odpolední program v rámci večírku Skupiny B. Braun CZ/SK. Děkujeme za úžasnou realizaci naší akce. Užili jsme si to!", rating: 5, imageUrl: imgBraun },
    { name: "siemens", author: "Nikola Stýbar", description: "Hru jsme si užili, opravdu se líbila. Děkujeme za hru i vždy příjemnou komunikaci.", rating: 5, imageUrl: imgSiemens }
]

export const Teambuilding: React.FC = () => {

    return (
        <main style={{ textAlign: "justify" }}>
            <Helmet>
                <title>Teambuilding</title>
                <link rel="canonical" href={`https://www.zabavnamesta.cz/teambuilding`} />
            </Helmet>
            <Content background={colors.sections.section2.bgColor} >
                <h1>Teambuilding</h1>
                <div>
                    <p>Zvažujete šifrovačku jako součást vašeho teambuildingu či firemní akce? Líbí se vám některá z našich her a rádi byste ji měli jako součást vašeho teambuldingu? Pokud ano, stačí nás kontaktovat a určitě najdeme cestu, jak vám pomoci.</p>
                </div>
                <h2>Co nabízíme</h2>
                <Grid container>

                    <Grid item sm={12}>
                        <BulletItem><p>přizpůsobení hry vašim potřebám</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>úprava designu šifrovačky – doplnění loga vaší firmy apod.</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>můžeme pomoci s dohodutím občerstvení na trati</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>další úpravy dle vašich požadvků a našich možností</p></BulletItem>
                    </Grid>
                </Grid>


                <p>
                    Pokud vás to zaujalo, ozvěte se nám na <a href="mailto:info@zabavnamesta.cz">info@zabavnamesta.cz</a> popř. zavolejte na +420&nbsp;605&nbsp;557&nbsp;009 nebo
                    +420&nbsp;733&nbsp;395&nbsp;050.
                </p>

                <h2>Reference</h2>
                <CompanyReviews {...{ reviews }} />
            </Content>
        </main>
    )
}