import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from "react-router"
import { CitySelect } from "./CitySelect";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
          <Route path="/ostrava" component={()=> <App city="Ostrava"  /> }  />
          <Route path="/brno" component={()=> <App city="Brno"  /> }  />
          <Route path="/olomouc" component={()=> <App city="Olomouc"  /> }  />
          <Route path="/frydek-mistek" component={()=> <App city="Frydek-Mistek"  /> }  />
          <Route path="/velke-losiny" component={()=> <App city="Velke-Losiny"  /> }  />
          <Route path="/praha" component={()=> <App city="Praha"  /> }  />
          <Route component={()=> <App /> }  />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root") as HTMLElement
);
serviceWorker.register();
