import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Content } from "./layout/Content";

import { colors } from "./theme";
import { Grid } from "@material-ui/core";
import { City } from "./City";
const imgHeader = require("./img/header.png");
const imgOstrava = require("./img/city_ostrava.jpg");
const imgFrydek = require("./img/city_frydek.png");
const imgOlomouc = require("./img/city_olomouc.jpg");
const imgBrno = require("./img/city_brno.jpg");
const imgLosiny = require("./img/city_losiny.png");
const imgPraha = require("./img/city_praha.png");

export const CitySelect: React.FC = () => {
  const classes = useStyles({});

  return (
      <main className={classes.main}>
        <Content background={"rgba(100,100,100,0)"}>
          <h2 id="home">Zvolte město</h2>
          <Grid container>
            <City name="Olomouc" index={0} imageUrl={imgOlomouc} />
            <City name="Ostrava" index={1} imageUrl={imgOstrava}  />
            <City name="Frydek-Mistek" index={1} imageUrl={imgFrydek} isNew />
            <City name="Brno" index={0} imageUrl={imgBrno} isNew />
            <City name="Velke-Losiny" index={0} imageUrl={imgLosiny} isNew />
            <City name="Praha" index={0} imageUrl={imgPraha} isNew />
          </Grid>
        </Content>
      </main>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    background: "white",
    padding: "0px 0px",
    position: "relative",
    margin: "0px 0px",
  },
  justify: {
    textAlign: "justify",
  },
  header: {
    //backgroundColor: "#ebffe9",

    backgroundColor: colors.header.image.bgColor,
    minHeight: 500,
  },
  mainLogo: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mainHeading: {
    fontFamily: "SourceSansPro-bold",
    fontWeight: 700,
    fontSize: "60px",
    color: "#2e2e2f",
    marginLeft: 30,
    lineHeight: "65px",
    marginTop: 20,
    textShadow: "1px 1px #C1C1C1",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  title: {
    marginTop: 140,
    fontWeight: 800,
    fontSize: 20,
    lineHeight: 1.5,
    textTransform: "uppercase",
  },
}));
