import React from "react";
interface IconProps {
	fill: string;
	class?: string;
	className?: string;
	height: number;
}


const TrophyIcon = (props: IconProps) => (
	<svg fill={props.fill} className={props.class} viewBox="0 0 462.999 462.999" width={props.height} height={props.height} >

<path d="M415.5,64H359V45.766c9.29-3.138,16-11.93,16-22.266C375,10.542,364.458,0,351.5,0h-240C98.542,0,88,10.542,88,23.5
		c0,10.336,6.71,19.128,16,22.266V64H47.5C34.542,64,24,74.542,24,87.5v47.999c0,56.56,45.605,102.661,101.974,103.481
		c15.322,22.547,37.674,39.945,63.878,49.027c-3.638,4.14-5.852,9.561-5.852,15.492c0,9.821,6.058,18.25,14.632,21.757
		c-9.008,8.65-14.632,20.798-14.632,34.243c0,13.781,5.794,26.519,16,35.534v4.466c0,9.098-7.402,16.5-16.5,16.5
		c-17.369,0-31.5,14.131-31.5,31.5v8c0,4.143,3.358,7.5,7.5,7.5h144c4.142,0,7.5-3.357,7.5-7.5v-8c0-17.369-14.131-31.5-31.5-31.5
		c-9.098,0-16.5-7.402-16.5-16.5v-4.465c10.205-9.015,16-21.755,16-35.535c0-13.445-5.624-25.593-14.632-34.243
		C272.941,321.749,279,313.32,279,303.499c0-5.931-2.213-11.352-5.852-15.492c26.204-9.082,48.556-26.48,63.878-49.027
		C393.395,238.16,439,192.059,439,135.499V87.5C439,74.542,428.458,64,415.5,64z M111.5,15h240c4.687,0,8.5,3.813,8.5,8.5
		s-3.813,8.5-8.5,8.5h-240c-4.687,0-8.5-3.813-8.5-8.5S106.813,15,111.5,15z M39,135.499V87.5c0-4.687,3.813-8.5,8.5-8.5H104v88.499
		c0,20.026,4.646,38.984,12.909,55.864C73.084,218.112,39,180.714,39,135.499z M279.5,430.999c9.098,0,16.5,7.402,16.5,16.5v0.5H167
		v-0.5c0-9.098,7.402-16.5,16.5-16.5c17.369,0,31.5-14.131,31.5-31.5v-0.5h33v0.5C248,416.868,262.13,430.999,279.5,430.999z
		 M252.844,383.999h-42.688c-7.115-6.181-11.156-14.979-11.156-24.5c0-17.921,14.58-32.5,32.5-32.5s32.5,14.579,32.5,32.5
		C264,369.019,259.958,377.818,252.844,383.999z M255.5,294.999c4.687,0,8.5,3.813,8.5,8.5s-3.813,8.5-8.5,8.5h-48
		c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5H255.5z M231.5,279.999c-62.033,0-112.5-50.468-112.5-112.5V47h225v120.499
		C344,229.531,293.532,279.999,231.5,279.999z M424,135.499c0,45.215-34.085,82.613-77.909,87.864
		c8.262-16.88,12.909-35.838,12.909-55.864V79h56.5c4.687,0,8.5,3.813,8.5,8.5V135.499z"/>
	<path d="M319.5,111.999c-4.142,0-7.5,3.357-7.5,7.5v48c0,24.047-10.671,46.665-29.278,62.054c-3.192,2.64-3.64,7.367-1,10.56
		c1.483,1.793,3.625,2.72,5.784,2.72c1.683,0,3.377-0.563,4.775-1.721C314.345,222.863,327,196.032,327,167.499v-48
		C327,115.356,323.642,111.999,319.5,111.999z"/>
	<path d="M319.5,64c-4.142,0-7.5,3.357-7.5,7.5v16c0,4.143,3.358,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-16C327,67.357,323.642,64,319.5,64
		z"/>

	</svg>
)

export default TrophyIcon;