import { Grid } from '@material-ui/core';
import React from 'react';
import { BulletItem } from './layout/BulletItem';
import { Content } from './layout/Content';
import { colors } from "./theme";
import { Helmet } from 'react-helmet';


export const Deti: React.FC = () => {

    return (
        <main style={{ textAlign: "justify" }}>
            <Helmet>
                <title>Hromadné akce pro děti</title>
                <link rel="canonical" href={`https://www.zabavnamesta.cz/olomouc/deti`} />
            </Helmet>
            <Content background={colors.sections.section2.bgColor} >
                <h1>Hromadné akce pro děti</h1>
                <div>
                    <p>Pokud z jakéhokoliv důvodu chcete zabavit větší skupinu dětí a myslíte, že by šifrovačka mohla být pro
                        ně ta správná zábava, stačí se nám ozvat. Kromě šifrovaček nabízených na webu umíme připravit i hledání
                        pokladu.</p>
                </div>

                <h2>Co nabízíme</h2>
                <Grid container>
                    <Grid item sm={12}>
                        <BulletItem><p>přizpůsobení hry vašim potřebám</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>úprava designu šifrovačky – doplnění loga vaší firmy apod.</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>můžeme pomoci s dohodutím občerstvení na trati</p></BulletItem>
                    </Grid>
                    <Grid item sm={12}>
                        <BulletItem><p>další úpravy dle vašich požadvků a našich možností</p></BulletItem>
                    </Grid>
                </Grid>

                <p>
                    Pokud vás to zaujalo, ozvěte se nám na <a href="mailto:info@zabavnamesta.cz">mailto:info@zabavnamesta.cz</a> popř. zavolejte na +420&nbsp;605&nbsp;557&nbsp;009 nebo
                    +420&nbsp;733&nbsp;395&nbsp;050.
                </p>
            </Content>
        </main>
    )
}