import React from "react";
import { Box, Link, CssBaseline, Grid, Container } from "@material-ui/core";
import { Parallax } from "react-parallax";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { colors } from "../theme";
import imgLogoWithCaption from "../img/zabavna-mesta_2.png";
import ResponsiveToolbar from "./ResponsiveToolbar";
import ZabavnaMestaIcon from "../img/ZabavnaMestaIcon";
import { ICityProps } from "src/types";
import { getCityFriedlyName } from "src/utils";
const imgHeader = require("../img/header.png");

interface IHeaderProps extends Partial<ICityProps> {
  changeColor: string;
  changeColorOffset: number;
  showNavbar: boolean;
}

export const Header: React.FC<IHeaderProps> = ({city, showNavbar = true}) => {
  const classes = useStyles({});

  // React.useEffect(() => {

  //     const header = document.getElementById("myHeader");
  //     //@ts-ignore
  //     const sticky = header!.offsetTop;
  //     const headerColorChange = () => {
  //         //console.log("header", header!.offsetTop, "y", window.pageYOffset);
  //         if (window.pageYOffset > sticky) {
  //             header!.classList.add("sticky");
  //         } else {
  //             header!.classList.remove("sticky");
  //         }

  //         // if (!enableOffsetColor && window.pageYOffset > changeColorOffset)
  //         //     setEnableOffsetColor(true);
  //         // else if (enableOffsetColor && window.pageYOffset < changeColorOffset)
  //         //     setEnableOffsetColor(false);

  //     };
  //     if (changeColorOffset) {
  //         window.addEventListener("scroll", headerColorChange);
  //     }
  //     return () => {
  //         if (changeColorOffset) {
  //             window.removeEventListener("scroll", headerColorChange);
  //         }
  //     };
  // });

  return (
    <div>
      <CssBaseline />

      <div className={classes.header}>
        {showNavbar && <div className={clsx("header", classes.navbar)} id="myHeader">
          <ResponsiveToolbar
            logo={
              <a href="/">
                <img src={imgLogoWithCaption} className={classes.logo} alt="logo malé" />
              </a>
            }>
            <Link className={classes.link} href="/">
              Úvod
            </Link>
            <Link className={classes.link} href={"/" + city?.toLowerCase() + "/#jaknato"}>
              Jak to funguje
            </Link>
            <Link className={classes.link} href={"/" +city?.toLowerCase() + "/#hry"}>
              Nabízené hry
            </Link>
            <Link className={classes.link} href={"/" +city?.toLowerCase() + "/#otazky"}>
              Otázky a odpovědi
            </Link>

            <Link className={classes.link} href={"/" +city?.toLowerCase() + "/vysledkova-listina"}>
              Výsledková listina
            </Link>

          </ResponsiveToolbar>
        </div>}

        {!showNavbar && <div className={clsx("header", classes.navbar)} id="myHeader">
          <ResponsiveToolbar
            logo={
              <a href="/">
                <img src={imgLogoWithCaption} className={classes.logo} alt="logo malé" />
              </a>
            }>
            <Link className={classes.link} href="/">
              Úvod
            </Link>
            <Link className={classes.link} href={"/obchodni-podminky"}>
              Obchodní podmínky
            </Link>
            <Link className={classes.link} href={"/ochrana-osobnich-udaju"}>
              Ochrana osobních údajů
            </Link>
          </ResponsiveToolbar>
        </div>}

        <Parallax blur={0} bgImage={imgHeader} strength={100} className={classes.header}>
          <div style={{ height: 400 }}>
            <Container>
              <Grid container>
                <Box className={classes.title} display="flex">
                  <Box display="flex" className={classes.mainLogo}>
                    <ZabavnaMestaIcon height={150} fill="#2e2e2f" animate={true} />
                  </Box>
                  <Box display="flex">
                    <h1 className={classes.mainHeading}>
                      Zábavná
                      <br /> města
                      <br /> {city && <div className={classes.city}> {getCityFriedlyName(city)}</div>}

                    </h1>
                  </Box>
                </Box>
              </Grid>
            </Container>
          </div>
        </Parallax>
      </div>
      {/* <ContactUs open={open} onClose={() => setOpen(false)} /> */}
      <div className={classes.motto}>
        <Container>Objevujte kouzelná místa, pátrejte po správných řešení, zapojte své přátele do týmu a skvěle se bavte při venkovní únikovce.</Container>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 700,
  },
  appbarColor: {
    boxShadow: "0px 2px 4px -1px rgba(30,30,30,0.2), 0px 4px 5px 0px rgba(30,30,30,0.14), 0px 1px 10px 0px rgba(30,30,30,0.12);",
  },

  contact: {
    color: colors.header.contacts.color,
    fontWeight: 500,
    right: 15,
    top: 15,
    position: "absolute",
    zIndex: 11,
    fontSize: "1.3em",
    fontFamily: "SourceSansPro-light",
    cursor: "default",
    "& > span > a": {
      textDecoration: "none",
      color: colors.header.contacts.color,
    },
    "& a:hover": {
      textDecoration: "none",
      color: colors.header.contacts.hoverColor,
    },
  },
  navbar: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 500,
  },

  btnPlay: {},
  link: {
    color: colors.header.appbar.color,
    padding: 20,
    fontSize: "1.4em",
    cursor: "pointer",
    "&:hover": {
      //backgroundColor: "orange",
      cursor: "pointer",
      textDecoration: "none",
      color: colors.header.appbar.hoverColor,
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "1.2em",
      padding: 10,
      lineHeight: "40px",
    },
  },
  nestedLink: {
    color: colors.header.appbar.color,
    padding: 5,
    fontSize: "1.4em",
    "&:hover": {
      //backgroundColor: "orange",
      textDecoration: "none",
      color: colors.header.appbar.hoverColor,
    },
  },
  motto: {
    fontSize: 20,
    fontFamily: "SourceSansPro-bold",
    background: "#28a745",
    color: "white",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  contacts: {
    position: "absolute",
    right: theme.spacing(3),
    fontSize: "18px",
    top: 25,
    textAlign: "right",
    "& > span": {
      marginLeft: 20,
    },
    // [theme.breakpoints.down('xs')]: {
    //     display: "none",
    // },
    // [theme.breakpoints.up('md')]: {
    //     minWidth:550,
    // },
  },
  contactsShifted: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  mainHeading: {
    fontFamily: "SourceSansPro-bold",
    fontWeight: 700,
    fontSize: "60px",
    color: "#2e2e2f",
    marginLeft: 30,
    lineHeight: "65px",
    marginTop: 20,
    textShadow: "1px 1px #C1C1C1",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  title: {
    marginTop: 140,
    fontWeight: 800,
    fontSize: 20,
    lineHeight: 1.5,
    textTransform: "uppercase",
  },
  logo: {
    height: 40,
    transition: "height 1.5s ease",
    margin: 14,
  },
  mainLogo: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  header: {
    //backgroundColor: "#ebffe9",

    backgroundColor: colors.header.image.bgColor,
  },
  mailIcon: {
    verticalAlign: "bottom",
    marginRight: "5px",
    fontSize: "1em",
    marginLeft: 30,
    height: "1.2em",
  },
  phoneIcon: {
    verticalAlign: "bottom",
    fontSize: "1em",
    height: "1.2em",
  },
  city:{
      fontSize: "0.6em",
  }
}));
