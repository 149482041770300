import React from 'react';
import { Content } from './layout/Content';
import { colors } from "./theme";
import { Helmet } from 'react-helmet';


export const OsobniUdaje: React.FC = () => {
    return (
        <main style={{ textAlign: "justify" }}>
            <Helmet>
                <title>Ochrana osobních údajů</title>
                <link rel="canonical" href={`https://www.zabavnamesta.cz/ochrana-osobnich-udaju`} />
            </Helmet>
            <Content background={colors.sections.section2.bgColor}>
                <h1>Ochrana osobních údajů</h1>
                <div>
                    <p>ZÁBAVNÁ MĚSTA s. r. o., IČ 08969817, sídlem Bělkovice-Lašťany 686, 783 16, email <a href="mailto:info@zabavnamesta.cz">info@zabavnamesta.cz</a> dále jen “správce” zpracovává osobní údaje poskytnuté zákazníkem v souladu s Nařízením Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů.</p>
                    <p>Správce nejmenoval pověřence pro ochranu osobních údajů.</p>
                    <p>Níže uvedená pravidla upravují zásady zpracování osobních údajů zákazníků správce, kteří si objednali hru nabízenou správcem prostřednictvím webových stránek <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a></p>

                </div>


                <h2>Jaké kategorie údajů zpracováváme</h2>
                <p>Zpracováváme pouze ty údaje, které potřebujeme, abychom zákazníkovi mohli nabídnout naše šifrovací hry, dodržovali zákonné povinnosti a chránili své oprávněné zájmy.</p>
                <p>Jedná se zejména o:</p>

                <ol type="1">
                    <li><p>identifikační osobní údaje - jméno, příjmení, adresa, pokud je zákazník právnickou osobou také IČ</p></li>
                    <li><p>kontaktní osobní údaje - email, telefonní číslo</p></li>
                    <li><p>údaje o produktech - geolokační údaje,    transakční údaje, doporučení a nabídk$y</p></li>
                    <li><p>údaje z používání webových stránek a aplikací správce</p></li>

                </ol>

                <h2>Účel zpracování osobních údajů zákazníků</h2>
                <p>Osobní údaje jsou zpracovány za účelem vyřízení Vaší objednávky a poskytnutí šifrovací hry. Bez osobních údajů poskytnutých při objednání hry není správce schopen uzavřít smluvní vztah se zákazníkem, na jehož základě jsou poskytnuty podklady k hraní šifrovací hry. V případě, že máte zájem o naši hru, musíme získat a zpracovat vaše osobní údaje.</p>
                <p>Vaše osobní údaje používáme také za účelem přímého marketingu správce. Zpracování pro účely přímého marketingu lze považovat za zpracování prováděné z důvodu oprávněného zájmu správce. Zákazníkovi je vždy dána možnost vyjádřit nesouhlas s dalším nabízením produktů a služeb, které je činěno na základě oprávněného zájmu správce.</p>
                <p>Zpracování osobních údajů, které nespadá pod výše uvedené účely, může být prováděno jen se souhlasem zákazníka. Poskytnutí takového souhlasu je zcela na rozhodnutí zákazníka.</p>


                <h2>Způsob zpracování a uchování osobních údajů a doba jejich uložení</h2>
                <p>Správce uchovává osobní údaje pouze po nezbytně nutnou dobu v souladu s právními předpisy a likviduje je 10 let od jejich shromáždění při vzniku smluvního vztahu. </p>
                <p>Údaje, které jsou zpracovávány se souhlasem zákazníka jsou uchovány pouze po tu dobu, kdy správce má souhlas k jejich zpracování.</p>
                <p>Osobní údaje jsou uchovávány v elektronické podobě. Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů. K osobním údajům zákazníka mají přístup pouze jím pověřené osoby.</p>

                <h2>Příjemci osobních údajů zákazníka</h2>
                <p>Osobní údaje zákazníka jsou zpracovávány a spravovány správcem. Vzdálené zpracování a ukládání dat se neuskutečňuje. Všechny osobní údaje se zpracovávají v ČR, Vaše osobní údaje nepředáme do žádné třetí země (třetí země = země mimo EU) nebo mezinárodní organizaci.</p>
                <p>Správce může v případě zákonného požadavku vyplývajícího z právního předpisu předat osobní údaje příslušným úřadům.</p>
                <p>Správce může předat osobní údaje zákazníka zasmluvněným IT dodavatelům, kteří pro správce spravují web, eshop, aplikaci pro hraní hry.</p>

                <h2>Zdroje osobních údajů</h2>
                <p>Správce získává osobní údaje zákazníka přímo od zákazníka při vyplnění objednávky hry a nepřímo při využívání poskytovaných produktů a služeb, tzn. prostřednictvím webové stránky <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a></p>


                <h2>Práva zákazníka</h2>
                <p>Zákazník má právo na:</p>

                <ol type="1">
                    <li><p>přístup k osobním údajům - zákazník může bezplatně získat informace o osobních údajích zpracovávaným správcem</p></li>
                    <li><p>přenositelnost - získat údaje ve strojově čitelném formátu</p></li>
                    <li><p>opravu osobních údajů</p></li>
                    <li><p>vznesení námitky proti zpracování</p></li>
                    <li><p>výmaz osobních údajů - zejména tehdy nejsou-li osobní údaje již potřebné pro účely, pro které byly shromážděny nebo jinak zpracovány nebo byl-li odvolán souhlas se zpracováním či vznesena námitka proti zpracování a neexistují žádné převažující oprávněné důvody pro další zpracování</p></li>
                    <li><p>je-li zpracování prováděno na základě souhlasu zákazníka, má zákazník právo udělený souhlas zčásti nebo zcela odvolat - odvolání je možné zasláním emailu na adresu <a href="mailto:info@zabavnamesta.cz">info@zabavnamesta.cz</a></p></li>

                </ol>
                <p>Domnívá-li se zákazník, že jsou porušeny jeho práva na ochoranu osobních údajů má možnost                 podat stížnost proti zpracování osobních údajů u Úřadu pro ochranu osobních údajů, Pplk. Sochora 27, PSČ 170 00, Praha 7, <a href="https://www.uoou.cz">www.uoou.cz</a></p>

                <h2>Závěrečná ustanovení</h2>
                <p>Zaškrtnutím potvrzení o seznámení s podmínkami před odesláním objednávkového formuláře dostupného na webové stránce <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a> potvrzujete seznámení s podmínkami o ochraně osobních údajů a stvrzujete jejich přijetí.</p>
                <p>Tyto podmínky mohou být změněny správcem, aktuální znění je vždy dostupné prostřednictvím webové stránky <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a></p>


                <p>Tyto podmínky nabývají účinnosti 15. 4. 2020</p>
            </Content>
        </main>
    )
}

