import React from "react";
interface IconProps{
    fill:string;
    class?:string;
    className?: string;
    height:number;
}


const PuzzleIcon = (props:IconProps) =>(
    <svg fill={props.fill} className={props.class} viewBox="0 0 50.001 50.001" width={props.height} height={props.height} >
<g>
	<path d="M49.471,23.714l-7.442-7.444c-0.709-0.708-0.488-1.465,0.489-1.685c0,0,0.807-0.182,1.801-1.176
		c2.134-2.133,2.132-5.596,0-7.726c-2.135-2.134-5.593-2.134-7.728,0c-0.994,0.994-1.175,1.8-1.175,1.8
		c-0.22,0.979-0.979,1.198-1.686,0.488l-7.442-7.438c-0.708-0.709-1.868-0.709-2.576,0l-7.441,7.44
		c-0.707,0.71-0.488,1.469,0.489,1.688c0,0,0.808,0.182,1.801,1.174c2.131,2.133,2.134,5.594,0,7.729
		c-2.133,2.133-5.596,2.131-7.727,0c-0.993-0.995-1.175-1.802-1.175-1.802c-0.219-0.978-0.979-1.197-1.687-0.489l-7.441,7.442
		c-0.708,0.708-0.708,1.867,0,2.574l7.441,7.442c0.708,0.708,0.488,1.467-0.489,1.687c0,0-0.807,0.183-1.801,1.176
		c-2.134,2.135-2.134,5.594,0,7.727c2.133,2.133,5.593,2.135,7.727,0c0.995-0.994,1.175-1.8,1.175-1.8
		c0.22-0.979,0.979-1.198,1.686-0.487l7.442,7.438c0.708,0.709,1.868,0.709,2.576,0l7.443-7.44c0.708-0.708,0.487-1.469-0.489-1.687
		c0,0-0.809-0.182-1.802-1.174c-2.135-2.135-2.134-5.594,0-7.729c2.133-2.133,5.593-2.136,7.727,0
		c0.994,0.994,1.174,1.802,1.174,1.802c0.221,0.979,0.979,1.199,1.688,0.491l7.442-7.444C50.178,25.581,50.178,24.422,49.471,23.714
		z"/>
</g>

    </svg>
    )

    export default PuzzleIcon;