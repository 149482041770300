import React from "react";
interface IconProps{
    fill:string;
    class?:string;
    className?: string;
    height:number;
}


const TimeIcon = (props:IconProps) =>(
    <svg fill={props.fill} className={props.class} viewBox="0 0 477.424 477.424" width={props.height} height={props.height} >
<g>
	<g>
		<g>
			<path d="M370.605,149.413l20.872-29.471c3.782-5.344,2.522-12.744-2.832-16.539l-31.46-22.281
				c-5.346-3.779-12.753-2.516-16.537,2.828l-21.383,30.188c-15.779-7.383-32.725-12.683-50.484-15.495V86.358
				c0-7.858,6.432-14.293,14.293-14.293h7.883c7.87,0,14.304-6.437,14.304-14.292V14.301C305.261,6.435,298.827,0,290.957,0H186.455
				c-7.857,0-14.288,6.435-14.288,14.301v43.472c0,7.855,6.431,14.292,14.288,14.292h7.896c7.863,0,14.296,6.435,14.296,14.293
				v12.285c-19.037,3.027-37.142,8.875-53.867,17.119l-21.182-29.905c-3.788-5.345-11.194-6.609-16.539-2.821L85.598,105.31
				c-5.351,3.787-6.612,11.195-2.827,16.538l21.359,30.176c-34.584,34.511-56.003,82.211-56.003,134.821
				c0,105.09,85.499,190.579,190.586,190.579c105.086,0,190.584-85.489,190.584-190.579
				C429.297,232.894,406.758,184.112,370.605,149.413z M238.713,431.688c-79.87,0-144.845-64.979-144.845-144.844
				c0-79.867,64.975-144.846,144.845-144.846c79.865,0,144.848,64.979,144.848,144.846
				C383.561,366.708,318.578,431.688,238.713,431.688z"/>
			<path d="M358.124,285.889c-0.517-65.411-53.878-118.479-119.411-118.479c-0.322,0-0.639,0.029-0.951,0.029v118.45H119.3
				c-0.007,0.312-0.025,0.628-0.025,0.956c0,65.539,53.068,118.892,118.487,119.411c0.313,0,0.629,0.017,0.951,0.017
				c65.853,0,119.434-53.567,119.434-119.428C358.146,286.517,358.124,286.201,358.124,285.889z"/>
		</g>
	</g>
</g>

    </svg>
    )

    export default TimeIcon;