import React, { useState, useEffect } from 'react';
import { Hidden, TableBody, TableHead, TableRow, TableCell, Table, Box, Tabs, Tab, Typography, Grid, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import { Content } from './layout/Content';
import { colors } from "./theme";
import Skeleton from 'react-loading-skeleton';
import axios from "axios";
import { GameItem } from "./layout/Game";
import { config } from "./config";
import { Error } from "./layout/Error";
import { EmptyData } from "./layout/EmptyData";
import { HumanizeDurationLanguage, HumanizeDuration } from 'humanize-duration-ts';
import { Badge } from "./layout/Badge";
import { ICityProps } from './types';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { getCityFriedlyName } from './utils';
interface ITabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}
class ResultItem {
    public readonly Id: number | undefined = undefined;
    public readonly TeamName: string | undefined = undefined;
    public readonly TeamId: number | undefined = undefined;
    public readonly GameName: string | undefined = undefined;
    public readonly GameId: number | undefined = undefined;
    public readonly GameDurationInMinutes: number | undefined = undefined;
    public TotalTime: number | undefined = undefined;
    public readonly GameStartDateTime: string | undefined = undefined;
    public readonly GameEndDateTime: string | undefined = undefined;
    public readonly IsFinished: boolean | undefined = undefined;
    public readonly IsCanceled: boolean | undefined = undefined;
    public readonly RequestedHintsCount: number | undefined = undefined;
    public readonly RequestedSolutionsCount: number | undefined = undefined;

    public GameStart: Date = new Date();

}

class ValidGameItem extends GameItem {
    public results: ResultItem[] = [];
}

const TabPanel: React.FC<ITabPanelProps> = (props: ITabPanelProps) => {
    const { children, value, index } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}

        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

export const Vysledky: React.FC<ICityProps> = ({city}) => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }
    const skeletons = [];
    for (let i = 0; i < 15; i++) skeletons.push(i);

    const [isLoadingResults, setIsLoadingResults] = useState<boolean>(true);
    const [isLoadingGames, setIsLoadingGames] = useState<boolean>(true);
    const [games, setGames] = useState<GameItem[] | null>(null);
    const [results, setResults] = useState<ResultItem[] | null>(null);
    const [validGames, setValidGames] = useState<ValidGameItem[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    const loadGames = useCallback(async () => {
        try {
            const result = await axios.get(`${config.gamesUrl}/${city}`);
            if (result?.data?.length) {
                //console.log("Obtained data", result.data);
                setGames(result.data);
            }
            else {
                console.error("Failed to obtain games offer", result);
                setError("Nepodařilo se načíst data");
            }
        }
        catch (e) {
            console.error("Failed to obtain games offer");
            setError("Nepodařilo se načíst data her");
        }
        finally {
            setIsLoadingGames(false);

        }
    }, [city]);
    const loadResults = useCallback( async () => {
        try {
            const result = await axios.get(`${config.resultsUrl}/${city}`);
            if (result?.data?.length) {
                //console.log("Obtained data", result.data);
                setResults(result.data);
            }
            else if (result?.data && result.data.length === 0){
                setResults([]);
            }
            else {
                console.error("Failed to obtain Results", result);
                setError("Nepodařilo se načíst data");
            }
        }
        catch (e) {
            console.error("Failed to obtain Results");
            setError("Nepodařilo se načíst data výsledků");
        }
        finally {
            setIsLoadingResults(false);

        }
    },[city]);

    useEffect(() => {
        loadGames();
        loadResults();
        return () => {
            setGames(null);
            setResults(null);
            setValidGames(null);
        };
    }, [loadGames, loadResults, city]);

    useEffect(() => {
        if (!isLoadingGames && !isLoadingResults) {
            if (games && results) {
                const validGames: ValidGameItem[] = games.filter(g => g.IsActive).map((i: GameItem) => {
                    return { ...i } as ValidGameItem
                });
                for (const g of validGames) {
                    g.results = results.filter(r => r.GameId === g.GameId && r.IsFinished);
                }
                console.log("valid games", validGames);
                setValidGames(validGames);
            }
            else {
                console.error("Nepodařilo se načíst data některého z výsledků");
                setError("Nepodařilo se načíst data některého z výsledků");
            }
        }

    }, [isLoadingGames, isLoadingResults, games, results]);


    return (
        <main>
            <Helmet>
                <title>Výsledková listina - {getCityFriedlyName(city)}</title>
                <link rel="canonical" href={`https://www.zabavnamesta.cz/${city ? city.toLowerCase(): ""}/vysledkova-listina`} />
            </Helmet>

            <Content background={colors.sections.section2.bgColor}>
                <h1>Výsledková listina</h1>
                {error && <Error />}
                {!error && (isLoadingGames || isLoadingResults) &&
                    <Paper style={{ padding: 10 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={3} md={2}>
                                <Skeleton height={30} />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <Skeleton height={30} />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <Skeleton height={30} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop: 15 }}>
                            {skeletons.map(s => (
                                <Grid item xs={12} key={s} >
                                    <Skeleton height={30} />
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
               
               }
                {!error && validGames && validGames.length === 0 && <EmptyData/> }
                {!error && validGames && validGames.length > 0 &&
                    <React.Fragment>
                        <Hidden mdDown>
                            <Paper >

                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className={classes.root}>
                                    {validGames.map((g, index) => (
                                        <Tab label={g.GameName} {...a11yProps(index)} key={g.GameName} />

                                    ))}

                                </Tabs>
                                {validGames.map((g, index) => (

                                    <ResultList index={index} results={g.results} value={value} key={index} />
                                ))}


                            </Paper>
                        </Hidden>
                        <Hidden lgUp>

                            {validGames.map((g, index) => (
                                <Paper>
                                    <h2>{g.GameName} </h2>
                                    <ResultList index={index} results={g.results} value={value} key={index} />
                                </Paper>
                            ))}


                        </Hidden>
                    </React.Fragment>


                }
            </Content>
        </main>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        //backgroundColor: "#8cda48",
    },
    bold: {
        fontWeight: "bold"
    }
}));

interface IResultListProps {
    index: number;
    results: ResultItem[];
    value: number;
}


const ResultList: React.FC<IResultListProps> = ({ index, results, value }) => {
    for (const r of results) {
        if (r.GameStartDateTime) r.GameStart = new Date(r.GameStartDateTime);

        if ( r.GameDurationInMinutes !== undefined &&  r.RequestedHintsCount!== undefined && r.RequestedSolutionsCount!== undefined)
            r.TotalTime = r.GameDurationInMinutes + r.RequestedHintsCount * 60 + r.RequestedSolutionsCount * 120;
        else
            console.error("Cannot parse results, some data doest not contain all neccessary attributes.");

    }
    const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
    langService.addLanguage("csShort", {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'hod',
        m: () => 'minut',
        s: () => 's',
        ms: () => 'ms',
        decimal: " "
    })
    const duration: HumanizeDuration = new HumanizeDuration(langService);
    results = results.sort((item1, item2) => {
        
        const b = item1.TotalTime;
        const a = item2.TotalTime;
        if (!a || !b)
            return -1;
        return a > b ? -1 : a < b ? 1 : 0;
    });

    return (
        <React.Fragment>
            <Hidden mdDown>
                <TabPanel value={value} index={index}>
                    <ResultTable results={results} duration={duration} />
                </TabPanel>
            </Hidden>
            <Hidden lgUp>
                <ResultTableSmall results={results} duration={duration} />
            </Hidden>
        </React.Fragment>
    )
}

interface IResultTableProps {

    results?: ResultItem[];
    duration: HumanizeDuration;
}

export const ResultTable: React.FC<IResultTableProps> = ({ results, duration }) => {
    return (
        results && results.length > 0 ?
            <Table aria-label="vysledky pro">
                <TableHead >
                    <TableRow>
                        <TableCell style={{ fontWeight: 700, width: 70 }}>Pořadí</TableCell>
                        <TableCell style={{ fontWeight: 700 }} align="left">Tým</TableCell>
                        <TableCell style={{ fontWeight: 700 }} align="left">Datum plnění</TableCell>
                        <TableCell style={{ fontWeight: 700 }} align="left">Celkový čas</TableCell>
                        <TableCell style={{ fontWeight: 700 }} align="left">Doba luštění</TableCell>
                        <TableCell style={{ fontWeight: 700 }} align="right">Počet nápověď</TableCell>
                        <TableCell style={{ fontWeight: 700 }} align="right">Počet řešení</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody style={{ fontWeight: 300 }}>
                    {results.map((row, index) => (
                        <TableRow key={`row${row.Id}`}>
                            <TableCell align="right" component="th" scope="row" style={{ width: 70 }}>
                                <Badge position={index} iconSize={20} />

                            </TableCell>
                            <TableCell align="left">{row.TeamName}</TableCell>
                            <TableCell align="left">{row.GameStart?.toLocaleDateString() ?? ""}</TableCell>
                            <TableCell align="left">{row.TotalTime ? duration.humanize(row.TotalTime * 60000, { language: "csShort", round: true, delimiter: " ", units: ["h", "m"] }) : " - "}</TableCell>
                            <TableCell align="left">{row.GameDurationInMinutes ? duration.humanize(row.GameDurationInMinutes * 60000, { language: "csShort", round: true, delimiter: " ", units: ["h", "m"] }) : " - "}</TableCell>
                            <TableCell align="right">{row.RequestedHintsCount ?? "-"}</TableCell>
                            <TableCell align="right">{row.RequestedSolutionsCount ?? "-"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            :
            <EmptyData />

    )
}

interface IResultTableSmallProps {

    results?: ResultItem[];
    duration: HumanizeDuration;

}

export const ResultTableSmall: React.FC<IResultTableSmallProps> = ({ results, duration }) => {
    return (
        results && results.length > 0 ?


            <Table aria-label="vysledky pro">
                <TableHead style={{ fontWeight: 700 }}>
                    <TableRow>
                        <TableCell>
                            <Grid container>
                                <Grid item xs={6} style={{ fontWeight: 700 }} >Pořadí</Grid>
                                <Grid item xs={6} style={{ fontWeight: 700, textAlign: "right" }}>Tým</Grid>
                                <Grid item xs={6} style={{ fontWeight: 700 }} >Datum plnění</Grid>
                                <Grid item xs={6} style={{ fontWeight: 700, textAlign: "right" }} >Celkový čas / Doba luštění</Grid>
                                <Grid item xs={6} style={{ fontWeight: 700 }} ></Grid>
                                <Grid item xs={6} style={{ fontWeight: 700, textAlign: "right" }}>Počet nápověď / Počet řešení</Grid>

                            </Grid>
                        </TableCell>
                    </TableRow>

                </TableHead>
                <TableBody style={{ fontWeight: 300 }}>
                    {results.map((row, index) => (
                        <TableRow key={`row${row.Id}`}>
                            <TableCell>
                                <Box display="flex">
                                    <Badge position={index} iconSize={20} />
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} > {row.TeamName}</Grid>
                                        <Grid item xs={6} >{row.GameStart?.toLocaleDateString() ?? ""} </Grid>
                                        <Grid item xs={6} style={{ textAlign: "right" }} >
                                            {row.TotalTime ? duration.humanize(row.TotalTime * 60000, { language: "csShort", round: true, delimiter: " ", units: ["h", "m"] }) : " - "}
                                            /
                                            {row.GameDurationInMinutes ? duration.humanize(row.GameDurationInMinutes * 60000, { language: "csShort", round: true, delimiter: " ", units: ["h", "m"] }) : " - "}

                                        </Grid>
                                        <Grid item xs={6} ></Grid>
                                        <Grid item xs={6} style={{ textAlign: "right" }}>{row.RequestedHintsCount ?? "-"} / {row.RequestedSolutionsCount ?? "-"}</Grid>
                                    </Grid>
                                </Box>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            :
            <EmptyData />

    )
}