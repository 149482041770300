import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Content } from "./layout/Content";

import { colors } from "./theme";
import { BulletItem } from "./layout/BulletItem";
import { IconColumn } from "./layout/IconColumn";
import ChallengeIcon from "./img/ChallengeIcon";
import FunIcon from "./img/FunIcon";
import ContestIcon from "./img/ContestIcon";
import { AnimatedMount, AnimationType } from "./layout/AnimatedMount";
import ResponsiveOrderedList, { ResponsiveOrderedListItem } from "./layout/ResponsiveOrderedList";
import { OfferedGames } from "./layout/OfferedGames";
import { Reviews } from "./layout/Reviews";
import { ActualInformation } from "./layout/ActualInformation";
import { useEffect } from "react";
import { DifferentCityContent } from "./layout/DifferentCityContent";
import { CityType } from "./types";
import { Fragment } from "react";
import { CityContent } from "./layout/CityContent";
import { CitySelect } from "./CitySelect";
import { getCityFriedlyName } from "./utils";
import { Helmet } from "react-helmet";

interface IMainProps {
  city?: CityType;
}

export const Main: React.FC<IMainProps> = ({ city }) => {
  const classes = useStyles({});
  const title = city ? `Zábavná města - ${getCityFriedlyName(city)}` : `Zábavná města`;
  useEffect(() => {
    document.title = title;
  });
  return (

    <main className={classes.main}>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={`https://www.zabavnamesta.cz/${city ? city.toLowerCase() : ""}`} />
      </Helmet>
      {/* <ActualInformation /> */}
      <Content background={colors.sections.section1.bgColor}>
        <h2 id="home">Úvod</h2>
        <Grid container>
          <Grid item sm={6}>
            <AnimatedMount onEnterAnimation={AnimationType.slideInLeft} duration={"1.3s"}>
              <BulletItem>Přijďte si užít venkovní šifrovací hru</BulletItem>
            </AnimatedMount>
          </Grid>
          <Grid item sm={6}>
            <AnimatedMount onEnterAnimation={AnimationType.slideInRight} duration={"1.3s"}>
              <BulletItem>Procvičte své šedé buňky mozkové při luštění hádanek, šifer a ukrytých indicií</BulletItem>
            </AnimatedMount>
          </Grid>
          <Grid item sm={6}>
            <AnimatedMount onEnterAnimation={AnimationType.slideInLeft} duration={"1.3s"}>
              <BulletItem>Projděte se známými místy netradičním způsobem (osvěžte si znalosti, či dokonce zjistěte něco nového) </BulletItem>
            </AnimatedMount>
          </Grid>
          <Grid item sm={6}>
            <AnimatedMount onEnterAnimation={AnimationType.slideInRight} duration={"1.3s"}>
              <BulletItem>Ideální počet hráčů 2-5</BulletItem>
            </AnimatedMount>
          </Grid>
          <Grid item sm={6}>
            <AnimatedMount onEnterAnimation={AnimationType.slideInLeft} duration={"1.3s"}>
              <BulletItem>Bavte se s rodinou, kamarády či kolegy a zjistěte, jak umíte spolu komunikovat a spolupracovat</BulletItem>
            </AnimatedMount>
          </Grid>
          <Grid item sm={6}>
            <AnimatedMount onEnterAnimation={AnimationType.slideInLeft} duration={"1.3s"}>
              <BulletItem>Luštění je prováděno starou dobrou papírovou formou, nebudete se mačkat u jednoho mobilu, šifry jsou vytištěny ve formátu A4 pro pohodlné luštění. Online aplikace vám nabídne nápovědy a řešení, budete-li je potřebovat.</BulletItem>
            </AnimatedMount>
          </Grid>
        </Grid>
      </Content>
      <Content background={colors.sections.section4.bgColor}>
        <h2 id="tldr">Co je to šifrovací hra</h2>
        <div className={classes.justify}>Šifrovací hra je zábava a zároveň výzva. Smyslem hry je dostat se do cíle pomocí řešení hádanek, úkolů (šifer). Nemusíte se bát, že díky nevyluštěné šifře se nedostanete do cíle. Připravili jsme pro Vás nápovědy a řešení, které Vám pomohou v případě potřeby šifru vyřešit a přejít k další výzvě. Hru dokončí každý! Šifrovací hry jsou známé také jako venkovní únikové hry, rozdíl je v tom, že místo místnosti je vaším „hřištěm“ celé město.</div>
        <h3>Proč hrát šifrovací hru</h3>
        <AnimatedMount onEnterAnimation={AnimationType.slideInUp} duration={"1.5s"}>
          <Grid container>
            <Grid item sm={4}>
              <IconColumn icon={<ContestIcon fill={colors.game.icon.bgColor} height={50} hoverFill={colors.bullet.icon.hoverColor} />} title="Je to soutěž">
                Můžete si ověřit, jak na tom váš tým je. Měří se vám čas, který si následně můžete srovnat s časy jiných týmů.
                <br /> Jak dopadnete ve srovnání s jinými týmy?
              </IconColumn>
            </Grid>
            <Grid item sm={4}>
              <IconColumn icon={<ChallengeIcon fill={colors.game.icon.bgColor} height={50} hoverFill={colors.bullet.icon.hoverColor} />} title="Je to výzva">
                {" "}
                Zdoláte zapeklité šifry, budete schopni v týmu spolupracovat na řešení?
              </IconColumn>
            </Grid>
            <Grid item sm={4}>
              <IconColumn icon={<FunIcon fill={colors.game.icon.bgColor} height={50} hoverFill={colors.bullet.icon.hoverColor} />} title="Je to zábava">
                Jste ochotni vyzkoušet zábavu s tužkou a papírem v dnešní digitální době?{" "}
              </IconColumn>
            </Grid>
          </Grid>
        </AnimatedMount>
      </Content>
      <Content background={colors.sections.section4.bgColor}>
        <h2 id="teambuildng">Teambuilding</h2>
        <div className={classes.justify}>
          Zvažujete šifrovačku jako součást vašeho teambuildingu či firemní akce? S tím vám můžeme snadno pomoci, pro více informací klikněte <a href="/teambuilding">zde</a>.
        </div>
      </Content>
      <DifferentCityContent currentCity={city} olomouc={
        <Content background={colors.sections.section4.bgColor}>
          <h2 id="deti">Hromadné akce pro děti</h2>
          <div className={classes.justify}>
            Jste škola, dům dětí mládeže, skautský oddíl nebo jen potřebujete zabavit větší počet dětí? Pro více informací klikněte <a href="olomouc/deti">zde</a>.
          </div>
        </Content>} />
      <DifferentCityContent currentCity={city} empty={<CitySelect />} />

      <CityContent city={city} background={colors.sections.section3.bgColor}>
        <h2 id="jaknato">Jak to funguje</h2>
        <ResponsiveOrderedList>
          <ResponsiveOrderedListItem>Hru si objednáte prostřednictvím těchto webových stránek.</ResponsiveOrderedListItem>
          {city !== "Brno" && <ResponsiveOrderedListItem>Při objednávce zvolíte, zda si hru vyzvednete osobně, či ji budete chtít zaslat poštou.</ResponsiveOrderedListItem>}
          {city !== "Brno" && <ResponsiveOrderedListItem>Zvolíte-li osobní vyzvednutí hry, herní balíček (šifry, zalaminovaná mapa, propiska) si můžete vyzvednout ve výdejním místě během několika minut.</ResponsiveOrderedListItem>}
          {city !== "Brno" && <ResponsiveOrderedListItem>
            <DifferentCityContent
              currentCity={city}
              ostrava={
                <Fragment>
                  V případě osobního vyzvednutí si herní balíček vyzvednete ve výdejním místě. Herní balíčky pro hry v Ostravě vám vydá <a href="https://www.ostravainfo.cz/cz/info/infocentra/8566-infocentrum-vez.html" target="_blank" rel="noopener noreferrer">Informační centrum Věž</a>, které se nachází v budově Nové radnice.
                </Fragment>
              }
              olomouc={
                <Fragment>
                  V případě osobního vyzvednutí si herní balíček vyzvednete ve výdejním místě. Pro Tour de pub Olomouce je výdejním místem a zároveň první zastávkou na trase <a href="https://cs-cz.facebook.com/localepub/" target="_blank" rel="noopener noreferrer">LocAle Pub</a>. Herní balíčky pro ostatní hry v Olomouci získáte v Potraviny Klárka na Dolním náměstí.
                </Fragment>
              }
              frydek={
                <Fragment>
                  V případě osobního vyzvednutí si herní balíček vyzvednete ve výdejním místě. Herní balíčky vydává ve Frýdku-Místku kavárna <a href="https://www.facebook.com/LuCafeFM/" target="_blank" rel="noopener noreferrer"> Lu Cafe</a> v areálu zámku.
                </Fragment>
              }
              losiny={
                <Fragment>
                  V případě osobního vyzvednutí si herní balíček vyzvednete ve výdejním místě. Herní balíčky vydává Rekreační areál Losinka v Rapotíně. Osobní vyzvednutí je možné pouze v době provozu kempu (cca od dubna do října). Více o kempu a jeho otevírací době <a href="https://www.losinka.cz/" target="_blank" rel="noopener noreferrer">zde</a>.
                </Fragment>
              }
          />
          </ResponsiveOrderedListItem>}
{/*           {city !== "Brno" && <ResponsiveOrderedListItem>
            <DifferentCityContent
              currentCity={city}
              shared={<Fragment>Při objednávce zvolíte, zda si hru vyzvednete osobně, či ji budete chtít zaslat poštou.</Fragment>}
            />
          </ResponsiveOrderedListItem>} */}
          <ResponsiveOrderedListItem>V případě zaslání poštou, herní balíčky předáváme k přepravě ihned následující pracovní den, i tak je potřeba počítat s minimálně třemi pracovními dny na doručení.</ResponsiveOrderedListItem>
          <ResponsiveOrderedListItem>Vytvoříte si svůj tým (ideálně 2 až 5 členů) a můžete vyrazit.</ResponsiveOrderedListItem>
          <ResponsiveOrderedListItem>Kdy začnete hrát je jen na vás. Stačí mít u sebe zaslaný herní balíček.</ResponsiveOrderedListItem>
          <ResponsiveOrderedListItem>Spolu se šiframi získáte odkaz na webovou aplikaci, která vám bude měřit čas a také vám umožní získat nápovědy či řešení k šifrám, dostanete-li se do úzkých.</ResponsiveOrderedListItem>
          <ResponsiveOrderedListItem>V okamžiku, kdy máte herní balíček vám již nic nebrání se pustit do luštění. V balíčku jsou šifry. Každá vyluštěná šifra vás zavede na další místo. Takto absolvujete celou trasu, řešení poslední šifry je řešením celé šifrovačky.</ResponsiveOrderedListItem>
          <ResponsiveOrderedListItem>Herní balíček kromě šifer obsahuje i instrukce, jak postupovat, abyste vždy otevřeli tu správnou obálku se šifrou na správném místě.</ResponsiveOrderedListItem>
        </ResponsiveOrderedList>
      </CityContent>
      <CityContent city={city} background={colors.sections.section4.bgColor}>
        <h2 id="Recenze">Recenze</h2>
        <Reviews />
      </CityContent>
      {
        <CityContent city={city} background={colors.sections.section2.bgColor}>
          <h2 id="hry">Nabízené hry</h2>
          <OfferedGames city={city!} />
        </CityContent>
      }
      <CityContent city={city} background={colors.sections.section4.bgColor}>
        <h2 id="otazky">Otázky a odpovědi </h2>
        <ul className={classes.justify}>
          <h4>Je hra náročná fyzicky či mentálně?</h4>
          Hra není fyzicky náročná, jedná se o procházku zpestřenou luštěním šifer, není potřeba být mistr světa v jakékoliv fyzické aktivitě. Co se týče mentální náročnosti – luští se, není to o počítaní počtu oken v domě před vámi či sčítání 2+2. Jsou týmy, které projdou hru bez využití jediné nápovědy či řešení, jsou týmy, které nápovědy či řešení ve webové aplikaci využijí. Není potřeba se bát, že se zaseknete a nikam nedojdete, aplikace pomůže.
          <h4>Můžu přerušit hru?</h4>
          Ano, ale přístup k nápovědám a řešením je po dobu 48 hodin od startu hry, poté si již v aplikaci nic nezobrazíte.
          <h4>JAK MŮŽU ZÍSKAT HERNÍ BALÍČEK A CO OBSAHUJE?</h4>
          <DifferentCityContent
            currentCity={city}
            shared={
              <Fragment>
                Herní balíček si můžete osobně vyzvednout nebo si jej nechat zaslat poštou. V případě osobního převzetí je balíček připraven v rámci minut po uhrazení objednávky. V případě zaslání poštou počítejte průměrně se třemi pracovními dny. Balíček obsahuje obálky s šiframi, mapku a propisku. Herní balíčky zasíláme poštou či prostřednictvím Zásilkovny.
              </Fragment>
            }
            brno={
              <Fragment>
                Herní balíčky zasíláme poštou či prostřednictvím Zásilkovny. Balíček obsahuje obálky se šiframi, mapku a propisku.
              </Fragment>
            }
          />

          {city !== "Brno" &&
            <Fragment>
              <h4>Kde si můžu herní balíček vyzvednout?</h4>
              <DifferentCityContent
                currentCity={city}
                olomouc={
                  <Fragment>
                    Pro Tour de pub Olomouce je výdejním místem a zároveň první zastávkou na trase <a href="https://cs-cz.facebook.com/localepub/" target="_blank" rel="noopener noreferrer">LocAle Pub</a>. Herní balíčky pro ostatní hry v Olomouci získáte v Potraviny Klárka na Dolním náměstí.
                  </Fragment>
                }
                ostrava={
                  <Fragment>
                    Herní balíčky pro hry v Ostravě vám vydá <a href="https://www.ostravainfo.cz/cz/info/infocentra/8566-infocentrum-vez.html" target="_blank" rel="noopener noreferrer">Informační centrum Věž</a>, které se nachází v budově Nové radnice.
                  </Fragment>
                }
                frydek={
                  <Fragment>
                    Herní balíčky vydává ve Frýdku-Místku kavárna <a href="https://www.facebook.com/LuCafeFM/" target="_blank" rel="noopener noreferrer"> Lu Cafe</a> v areálu zámku. Adresa Lu Cafe je Zámecké nám. 1264, Frýdek-Místek.
                  </Fragment>
                }
                losiny={
                  <Fragment>
                    V případě osobního vyzvednutí si herní balíček vyzvednete ve výdejním místě. Herní balíčky vydává Rekreační areál Losinka v Rapotíně. Osobní vyzvednutí je možné pouze v době provozu kempu (cca od dubna do října) . Více o kempu a jeho otevírací době <a href="https://www.losinka.cz/" target="_blank" rel="noopener noreferrer">zde</a>. Adresa kempu je U Koupaliště 532, Rapotín.
                  </Fragment>
                }
              />
            </Fragment>
          }
          <h4>Opravdu mohu začít hrát kdykoliv?</h4>
          Jediné, co je potřeba vzít v potaz, je otevírací doba. &nbsp;
          <DifferentCityContent
            currentCity={city}
            olomouc={
              <Fragment>
                Potraviny Klárka na Dolním náměstí v Olomouci jsou otevřeny každý den od 7 do 19 hod.
                <br />
                <a href="https://cs-cz.facebook.com/localepub/" target="_blank" rel="noopener noreferrer">LocAle Pub</a> má volný den v neděli, je otevřen od pondělí do soboty od 16 hod. (samozřejmě pokud nouzový stav nenařizuje zavření restaurací). <br />
              </Fragment>
            }
            ostrava={
              <Fragment>
                <a href="https://www.ostravainfo.cz/cz/info/infocentra/8566-infocentrum-vez.html" target="_blank" rel="noopener noreferrer">Informační centrum Věž</a> v budově Nové radnice v Ostravě je otevřeno každý den od 9 do 17 hod.
                <br />
              </Fragment>
            }
            frydek={
              <Fragment>
                <a href="https://www.facebook.com/LuCafeFM/" target="_blank" rel="noopener noreferrer"> Lu Cafe</a> je otevřeno každý den od 10 do 18 hod.
                <br />
              </Fragment>
            }
          />
          Doporučujeme si před startem ověřit aktuální otevírací dobu, dnes se mění vše z hodiny na hodinu. Pokud vám otevírací doba výdejních míst nevyhovuje a nechcete být na ně vázaní, můžete využít zaslání herní balíčku poštou.
          <h4>Je terén vhodný pro kočárky?</h4>
          <DifferentCityContent 
            currentCity={city}
            olomouc={
              <Fragment>
                Na Tour de pub bychom nedoporučili brát děti tedy ani kočárky. U ostatní her to není žádný problém.&nbsp;
              </Fragment>
            }
            losiny={
              <Fragment>
                Ačkoliv Losiny jsou součástí Jeseníků, můžete směle vyrazit do Losin i s kočárkem.
              </Fragment>
            }
            shared={
              <Fragment>
                Budete procházet městem, jedinou překážkou můžou být schody, kde bude potřeba kočárek snést dolů.
              </Fragment>
            }
          />
          <h4>BUDE MI STAČIT OPRAVDU JEN MÍT U SEBE ČI SI VYZVEDNOUT HERNÍ BALÍČEK, NIC DALŠÍHO NEPOTŘEBUJI?</h4>
          Balíček je základ, ale bez mobilu, tabletu či notebooku s přístupem na internet to nepůjde. Prostřednictvím webové aplikace zahájíte hru.  Webová aplikace vám také umožní získat nápovědu či řešení šifry, jestliže se při luštění dostanete do úzkých.
          <h4>Jak si mám představit dárkový voucher na šifrovačku?</h4>
          Při koupi šifrovačky máte možnost vybrat si variantu dárkového voucheru. Pokud si zvolíte, že chcete šifrovačku dát jako dárek, zašleme vám herní balíček s veškerými náležitostmi a navíc instrukcemi, jak hrát a hru odstartovat. Dárkový voucher a instrukce vytisknete a předáte. To je vše. Voucher a instrukce obsahují vše potřebné – kde je start hry, co mít s sebou apod. Platnost voucheru je 6 měsíců. Jak vypadá voucher se můžete podívat{" "}
          <a href="https://shop.zabavnamesta.cz/Content/Files/darkovy_poukaz_vzor.pdf" rel="noopener noreferrer" target="_blank">
            zde
          </a>
          . V případě zaslání poštou vám dorazí balíček, který předáte vy osobně.
        </ul>
      </CityContent>
    </main>
  );
};

const useStyles = makeStyles(() => ({
  main: {
    background: "white",
    padding: "0px 0px",
    position: "relative",
    margin: "0px 0px",
  },
  justify: {
    textAlign: "justify",
  },
}));
