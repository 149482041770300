

import React, { Fragment } from 'react';
import { Box, Divider, Card, CardMedia, CardContent, Button } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../theme";
import { Ribbon } from "./Ribbon";
import CardioIcon from "../img/CardioIcon";
import PuzzleIcon from "../img/PuzzleIcon";
import PeopleIcon from "../img/PeopleIcon";
import DistanceIcon from "../img/DistanceIcon";
import TimeIcon from "../img/TimeIcon";
import imgLogoWithCaption from "../img/game_default.png";
import clsx from 'clsx';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOn';
import { CircleIcon } from './CircleIcon';
import Skeleton from 'react-loading-skeleton';
import { config } from "../config";
import { LevelIndicator } from "./LevelIndicator";
import { CustomGameIcon } from './CustomGameIcon';
import HomeDeliveryIcon from 'src/img/HomeDeliveryIcon';
import PostDeliveryIcon from 'src/img/PostDeliveryIcon';
import { ProductBox } from './ProductBox';
export enum DifficultyLevel {
    easy = 1,
    medium = 2,
    hard = 3,
}
export class GameItem {
    public readonly Id: number | undefined = undefined;
    public readonly GameName: string | undefined = undefined;
    public readonly Description: string | undefined = undefined;
    public readonly DifficultyLevel: DifficultyLevel | undefined = undefined;
    public readonly Price: Number | undefined = undefined;
    public readonly HourLimit: number | undefined = undefined;

    public readonly Place: string | undefined = undefined;
    public readonly IsActive: boolean | undefined = undefined;

    public readonly IsAction: boolean | undefined = undefined;
    public readonly ActionText: string | undefined = undefined;
    public readonly IsForKids: boolean | undefined = undefined;

    public readonly DifficultyText?: string | undefined = undefined;
    public readonly PuzzleText?: string | undefined = undefined;
    public readonly DistanceText?: string | undefined = undefined;
    public readonly PuzzleTimeText?: string | undefined = undefined;
    public readonly RecommendedAgeText?: string | undefined = undefined;
    public readonly GameImageFilePath?: string | undefined = undefined;
    public readonly ActionSignColorHexCode?: string | undefined = undefined;
    public readonly ActionSignTextColorHexCode?: string | undefined = undefined;
    //public readonly PriceCurrency:  string |undefined = undefined;
    //public readonly GameLanguage: string |undefined = undefined;
    //public readonly CodeExpirationInMonths: string |undefined = undefined;
    //public readonly ActionSignColor: string |undefined = undefined;
    public readonly GameId: string | undefined = undefined;
    public readonly TotalRating: number | undefined = undefined;
    //public readonly IconItemFileName:  string |undefined = undefined;
    //public readonly OtherInformation:  string |undefined = undefined;
    //public readonly Images: string |undefined = undefined;
    public readonly IsHomeDelivery: boolean | undefined = undefined;
    public readonly IsPersonalDelivery: boolean | undefined = undefined;

}

interface IGameProps {

    game?: GameItem;
    icon?: React.ReactNode;
    isLoading?: boolean;
}

export const Game: React.FC<IGameProps> = ({ game, icon, isLoading = false }) => {
    const classes = useStyles({});

    if (!game) {
        isLoading = true;
    }

    const renderDescription = (text: string | undefined) => {
        if (!text || !text.trim().length)
            return "";
        const splits = text.split(/<br\s*[\\/]?>/gi);
        return (
            <div>
                {splits.map((s, index) => <p key={index}>{s}</p>)}
            </div>
        );
    }

    const onClick = () => {
        if (game)
            window.location.href = (`${config.detailUrl}/${game.Id}`);
    }
    const renderParameter = (icon: React.ReactNode, value: string | number | undefined, format?: string) => {
        if (isLoading) {
            return (
                <div className={classes.line}>
                    <p className={clsx(classes.skeleton, classes.skeletonIcon)}><Skeleton height={16} /></p>
                    <p className={classes.skeleton}><Skeleton height={16} /> </p>
                </div>
            );
        }
        else if (value) {
            return (
                <div className={classes.line}>
                    {icon && icon}
                    <p>{format ? format.replace("{0}", value.toString()) : value.toString()}</p>
                </div>
            );
        }
        return undefined;
    }
    const renderRibbon=(game?: GameItem)=>{
        if (game?.IsAction) 
            return <Ribbon text={game?.ActionText || "Akce"} bgColor={game?.ActionSignColorHexCode || colors.game.ribbon.discount.bgColor} color={game?.ActionSignTextColorHexCode || colors.game.ribbon.discount.color} />
        else if (game?.IsActive === false) 
            return <Ribbon text={game?.ActionText || "Připravujeme"} bgColor={game?.ActionSignColorHexCode || colors.game.ribbon.discount.bgColor} color={game?.ActionSignTextColorHexCode || colors.game.ribbon.discount.color} />
        else 
            return null;
    }
    return (
        <ProductBox 
            isLoading={!game}
            imageUrl={game?.GameImageFilePath || imgLogoWithCaption}
            title={game?.GameName}
            iconComponent={
                isLoading ?
                    <div style={{ position: "absolute", left: 40, top: -30 }}>
                        <Skeleton circle height={80} width={80} />

                    </div>
                    :
                    <CircleIcon size={80} bgColor={colors.game.icon.bgColor} hoverColor={colors.game.icon.hoverColor} style={{ position: "absolute", left: 40, top: -30 }}>
                        <div className={classes.circle}>
                            {icon}
                        </div>
                    </CircleIcon>
            }
            ribbonComponent={renderRibbon(game)} 
            
            content={
                    <div className={classes.content}>
                    <h2 className={classes.title} > {isLoading ? <Skeleton height={40} /> : game?.GameName}</h2>
                    {isLoading ?
                        <div style={{ marginBottom: 20 }}><Skeleton count={3} /></div>
                        :
                        renderDescription(game?.Description)
                    }
                    <div className={classes.inner}>
                        {renderParameter(<CardioIcon fill="black" height={24} className={classes.icon} />, game?.DifficultyText)}
                        {renderParameter(<PuzzleIcon fill="black" height={24} className={classes.icon} />, game?.PuzzleText)}
                        {renderParameter(<DistanceIcon fill="black" height={24} className={classes.icon} />, game?.DistanceText)}
                        {renderParameter(<TimeIcon fill="black" height={24} className={classes.icon} />, game?.PuzzleTimeText)}
                        {renderParameter(<PeopleIcon fill="black" height={24} className={classes.icon} />, game?.RecommendedAgeText)}
                    </div>
                    </div>    
            }
            actionArea={
                <div className={classes.actionArea}>
                    <Box display="flex" flexDirection="column" flexGrow={1} textAlign="right">
                        <Box display="flex" flexDirection="row" className={classes.city}>
                            <div>
                                {isLoading ?
                                    <Skeleton circle height={28} width={28} />
                                    :
                                    game?.HourLimit && <CircleIcon size={28} title="hodinový limit" bgColor={colors.game.hours.bgColor} hoverColor={colors.game.hours.hoverColor}>
                                        <span style={{ fontSize: 12, cursor: "cursor" }}>{game?.HourLimit}h</span>
                                    </CircleIcon>
                                }
                            </div>
                            <div>
                                {isLoading ?
                                    <Skeleton circle height={28} width={28} />
                                    :
                                    game?.DifficultyLevel && game?.DifficultyLevel <= DifficultyLevel.hard && game.DifficultyLevel >= DifficultyLevel.easy && <LevelIndicator level={game.DifficultyLevel} />
                                }
                            </div>
                            <div>
                                {isLoading ?
                                    <Skeleton circle height={28} width={28} />
                                    :
                                    (game?.IsPersonalDelivery) && <CustomGameIcon size={28} bgColor={colors.game.homeDelivery.bgColor} hoverColor={colors.game.homeDelivery.hoverColor} title="Osobní vyzvednutí je k dispozici"><HomeDeliveryIcon height={16} fill="white" /></CustomGameIcon>
                                }
                            </div>
                            <div>
                                {isLoading ?
                                    <Skeleton circle height={28} width={28} />
                                    :
                                    (game?.IsHomeDelivery) && <CustomGameIcon size={28} bgColor={colors.game.postDelivery.bgColor} hoverColor={colors.game.postDelivery.hoverColor} title="Doručení poštou je k dispozici"><PostDeliveryIcon height={16} fill="white" /></CustomGameIcon>
                                }
                            </div>
                            
                           
                        </Box>
                        <Box display="flex" flexGrow={1} textAlign="left" justifyContent="flex-end" alignItems="flex-end">
                                {isLoading ?
                                    <Box flexGrow={1} style={{ marginLeft: 5 }}>    <Skeleton height={28} /></Box>
                                    :
                                    <Box flexGrow={1}><LocationOnOutlinedIcon className={classes.locationIcon} /> <span> {game?.Place ?? ""} </span></Box>
                                }
                            </Box>
                        <Divider />

                        <hr />
                        <Rating value={game?.TotalRating} precision={0.5} size="small" readOnly={true} disabled={game?.TotalRating === 0}  />
                        <Box display="flex" flexGrow={1} alignItems="center" >
                            <div className={classes.price}>
                                {isLoading ?
                                    <Skeleton height={30} width={130} />
                                    : (game?.Price ? <span>{game?.Price}&nbsp;Kč</span> : <div />)
                                }
                            </div>
                            <Box display="Flex" className={classes.buy} >
                                <Button variant="contained" color="primary" disabled={isLoading || game?.IsActive === false} onClick={onClick} className={classes.detailButton}>
                                        Detail
                                </Button>
                                <Button variant="contained" color="primary" disabled={isLoading || game?.IsActive === false} onClick={onClick}>
                                        Koupit
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                </div>
            }
            />

        
    )
}
const useStyles = makeStyles(() => ({
    root: {
        textAlign: "left",
        fontSize: "1em",
        padding: 10,
        display: "flex",
        position: "relative",
        marginTop: 10,
        height: "100%",

    },
    icon: {
        height: 30,
    },
    skeleton: {
        flexGrow: 1,
    },
    skeletonIcon: {
        width: "20px",
        flexGrow: 0,
        marginLeft: "0px !important",
    },
    line: {
        marginBottom: 10,
        display: "flex",
        "& svg": {
            display: "block",
            minWidth: 24,
        },
        "& p": {
            display: "block",
            marginLeft: 10,
            marginTop: 2,
            marginBottom: 0,
        }
    },
    main: {
        height: "100%",
        flexGrow: 1,
    },
    inner: {
        margin: "0px 15px",
    },
    media: {
        display: "flex",
        justifyContent: "center",
    },
    mediaBg: {
        height: 250,

    },
    circle: {
        padding: 15,
        width: "100%",
        height: "100%",
        // height: circleSize,
        // width: circleSize,
        // borderRadius: circleSize / 2,
        // background: colors.game.icon.bgColor,
        // position: "absolute",
        // left: 40,
        // top: -30,

    },
    city: {
        fontSize: "16px",
        marginBottom: 3,
    },
    locationIcon: {
        verticalAlign: "middle",
        fontSize: 19,
        color: "black",

    },
    preview: {

        position: "absolute",
        right: "-5px; top: -5px",
        zIndex: 1,
        overflow: "hidden",
        width: "75px; height: 75px",
        textAlign: "right",

        "& span": {
            fontSize: 10,
            fontWeight: "bold",
            color: "#FFF",
            textTransform: "uppercase",
            textAlign: "center",
            lineHeight: 20,
            transform: "rotate(45deg)",

            width: 100,
            display: "block",
            // background: "linear-gradient(#F70505 0%, #8F0808 100%)",
            background: colors.bullet.icon.bgColor,
            boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 1)",
            position: "absolute",
            top: 19,
            right: -21,
        }
    },
    title: {
        fontSize: "24px !important",
        lineHeight: "26px  !important",
        paddingBottom: 10,
        textAlign: "center",
        minHeight: 60,

    },
    content: {
        fontSize: "0.8em",
        marginBottom: 190,
    },
    actionArea: {
        position: "absolute",
        bottom: 25,
        textAlign: "center",
        display: "flex",
        left: 25,
        right: 25,
        justifyContent: "center",
    },
    price: {
        flexGrow: 1,
        fontSize: "1.7em",
        width: "100%",
        textAlign: "left",
    },
    buy: {
        "& > button":{
            marginLeft:5,
        }
    },
    detailButton:{
        backgroundColor: "orange",
        "&:hover":{
            backgroundColor: "darkorange",

        }
    }

}));