import React, { FunctionComponent, ReactNode } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { config } from "../../config";
import { Review, ReviewItem } from "../../layout/Review";


import axios from "axios";
import { Error } from "../../layout/Error";
import { AnimationType, AnimatedMount } from '../../layout/AnimatedMount';


export type CompanyReviewItem ={
    id?: string;
    name: string;
    author?: string;
    description?: string;
    imageUrl?: string;
    rating?: number;
}

type ReviewListProps = {
    reviews: CompanyReviewItem[] | null;
    isLoading?: boolean;
}

const getAnimation = (index: number) => {
    if (index % 3 === 0) return AnimationType.slideInLeft;
    else if (index % 3 === 1) return AnimationType.slideInUp;
    else return AnimationType.slideInRight;
};

export const CompanyReviews: FunctionComponent<ReviewListProps> = ({
    reviews,
    isLoading = false,
}) => {
    const classes = useStyles({});
    return (
        <Grid container alignItems="stretch">
            {reviews!.filter((i, idx) => idx < 3).map((r, index) =>
                <Grid className={classes.root} item xs={12} sm={6} md={6} style={{ display: "flex" }} key={index}>
                    <AnimatedMount onEnterAnimation={getAnimation(index)} duration={"1.3s"} display="flex">
                        <Review isLoading={isLoading} title={r.name} subtitle={r.author} description={r.description} rating={r.rating} imageUrl={r.imageUrl} />
                    </AnimatedMount>
                </Grid>
            )}
        </Grid>
    )
};

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },

}));