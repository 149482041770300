import React from "react";
interface IconProps{
    fill:string;
    class?:string;
    className?: string;
    height:number;
}


const PeopleIcon = (props:IconProps) =>(
    <svg fill={props.fill} className={props.class} viewBox="0 0 511.999 511.999" width={props.height} height={props.height} >
<g>
	<g>
		<path d="M438.09,273.32h-39.596c4.036,11.05,6.241,22.975,6.241,35.404v149.65c0,5.182-0.902,10.156-2.543,14.782h65.461
			c24.453,0,44.346-19.894,44.346-44.346v-81.581C512,306.476,478.844,273.32,438.09,273.32z"/>
	</g>
</g>
<g>
	<g>
		<path d="M107.265,308.725c0-12.43,2.205-24.354,6.241-35.404H73.91c-40.754,0-73.91,33.156-73.91,73.91v81.581
			c0,24.452,19.893,44.346,44.346,44.346h65.462c-1.641-4.628-2.543-9.601-2.543-14.783V308.725z"/>
	</g>
</g>
<g>
	<g>
		<path d="M301.261,234.815h-90.522c-40.754,0-73.91,33.156-73.91,73.91v149.65c0,8.163,6.618,14.782,14.782,14.782h208.778
			c8.164,0,14.782-6.618,14.782-14.782v-149.65C375.171,267.971,342.015,234.815,301.261,234.815z"/>
	</g>
</g>
<g>
	<g>
		<path d="M256,38.84c-49.012,0-88.886,39.874-88.886,88.887c0,33.245,18.349,62.28,45.447,77.524
			c12.853,7.23,27.671,11.362,43.439,11.362c15.768,0,30.586-4.132,43.439-11.362c27.099-15.244,45.447-44.28,45.447-77.524
			C344.886,78.715,305.012,38.84,256,38.84z"/>
	</g>
</g>
<g>
	<g>
		<path d="M99.918,121.689c-36.655,0-66.475,29.82-66.475,66.475c0,36.655,29.82,66.475,66.475,66.475
			c9.298,0,18.152-1.926,26.195-5.388c13.906-5.987,25.372-16.585,32.467-29.86c4.98-9.317,7.813-19.946,7.813-31.227
			C166.393,151.51,136.573,121.689,99.918,121.689z"/>
	</g>
</g>
<g>
	<g>
		<path d="M412.082,121.689c-36.655,0-66.475,29.82-66.475,66.475c0,11.282,2.833,21.911,7.813,31.227
			c7.095,13.276,18.561,23.874,32.467,29.86c8.043,3.462,16.897,5.388,26.195,5.388c36.655,0,66.475-29.82,66.475-66.475
			C478.557,151.509,448.737,121.689,412.082,121.689z"/>
	</g>
</g>
    </svg>
    )

    export default PeopleIcon;