import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { colors } from "../theme";

interface IStyleProps {
    bgColor?: string;
    color?: string;
}
interface IRibbonProps extends IStyleProps {

    text: string;
}

export const Ribbon: React.FC<IRibbonProps> = ({ text,
    bgColor = colors.game.ribbon.preview.bgColor,
    color = colors.game.ribbon.preview.color }) => {
    const classes = useStyles({ bgColor, color });
    return (
        <div className={classes.root}>
            <span>{text}</span>
        </div>

    )
}

const useStyles = makeStyles<Theme, IStyleProps>(() => ({
    root: props => ( {
        position: "absolute",
        right: 5,
        top: 5,
        zIndex: 1,
        overflow: "hidden",
        width: 200,
        height: 200,
        textAlign: "right",
        "& span": {
            fontSize: 16,
            fontWeight: "bold",
            color: props.color,
            textTransform: "uppercase",
            textAlign: "center",
            lineHeight: "28px",
            transform: "rotate(45deg)",
            width: "152px",
            display: "block",
            background: props.bgColor,

            boxShadow: "0 3px 10px -5px rgba(0, 0, 0, 1)",
            position: "absolute",
            top: 30,
            right: -30,
        },
        "& span::before": {
            content: "''",
            position: "absolute",
            left: 0,
            top: "100%",
            zIndex: -1,
            borderLeft: `3px solid ${props.bgColor}`,
            borderRight: "3px solid transparent",
            borderBottom: "3px solid transparent",
            borderTop: `3px solid ${props.bgColor}`,
        },
        "& span::after": {
            content: "''",
            position: "absolute",
            right: 0,
            top: "100%",
            zIndex: -1,
            borderLeft: "3px solid transparent",
            borderRight: `px solid ${props.bgColor}`,
            borderBottom: "3px solid transparent",
            borderTop: `3px solid ${props.bgColor}`,
        }
    })}
));