import React, { Fragment, ReactElement } from "react";
import { CityType } from "src/types";

interface IDifferentCityContentProps {
    currentCity?: CityType;
    ostrava?: React.ReactNode;
    olomouc?: React.ReactNode;
    frydek?: React.ReactNode;
    brno?: React.ReactNode;
    losiny?: React.ReactNode;
    praha?: React.ReactNode;
    empty?: React.ReactNode;
    shared?: React.ReactNode;

}

export const DifferentCityContent: React.FC<IDifferentCityContentProps> = ({ currentCity, olomouc, ostrava, frydek, brno, losiny, praha, empty, shared }) => {
    console.log("diff city", currentCity, brno, empty);
    return (
        <Fragment>
            {currentCity === undefined && empty}
            {currentCity &&
                <Switch currentCity={currentCity}>
                    <City name="Ostrava" shared={shared} content={ostrava} />
                    <City name="Olomouc" shared={shared} content={olomouc} />
                    <City name="Frydek-Mistek" shared={shared} content={frydek} />
                    <City name="Brno" shared={shared} content={brno} />
                    <City name="Velke-Losiny" shared={shared} content={losiny} />
                    <City name="Praha" shared={shared} content={praha} />                    
                </Switch>
            }
        </Fragment>
    )
}


interface ISwitchProps {

    currentCity?: CityType;
    //children:typeof City[];
    //children:ReactElement<typeof City,typeof City>;
    children: ReactElement<React.FC<ICityProps>, React.FC<ICityProps>>[]
    //children: React.ReactNode[];
}

const Switch: React.FC<ISwitchProps> = ({ currentCity, children }) => {
    const child = children.filter(i => i.props.name === currentCity);
    return (
        <Fragment>
            {child}
        </Fragment>
    )
}


interface ICityProps {

    name?: CityType;

    content: React.ReactNode;
    shared?: React.ReactNode;

}

const City: React.FC<ICityProps> = ({ content, shared }) => {
    return (
        <Fragment>
            {content ?? shared}
        </Fragment>
    )
}