import React from 'react';
import { Grid } from "@material-ui/core";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { AnimatedMount, AnimationType } from './AnimatedMount';
import { BulletItem } from './BulletItem';



export const ResponsiveOrderedListItem: React.FC<IResponsiveOrderedListItemProps> = ({ children }) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

interface IListProps {

    children: React.ReactNode[];
}
interface IResponsiveOrderedListProps extends IListProps {

    children: React.ReactNode[];
    width: Breakpoint;
}

const ResponsiveOrderedList: React.FC<IResponsiveOrderedListProps> = ({ children, width }) => {
    const fullWidth = isWidthUp('md', width);
    const filteredChildren = children.filter(i => i !== false);
    return fullWidth ? <TwoColumnList {...{ children: filteredChildren }} /> : <SingleColumnList {...{ children: filteredChildren }} />
}

const SingleColumnList : React.FC<IListProps> = ({ children }) => {
    return (
        <Grid container>
            {children.map((i, index) =>
                <Grid item sm={12} key={"item" + index} >
                    <AnimatedMount onEnterAnimation={AnimationType.slideInLeft} >
                        <BulletItem number={index + 1}> {i}  </BulletItem>
                    </AnimatedMount>
                </Grid>
            )}
        </Grid>
    )
}
const TwoColumnList: React.FC<IListProps> = ({ children }) => {
    const pivot = children.length % 2 === 0 ? children.length / 2 : (children.length + 1) / 2;
    const reorder = (items: React.ReactNode[]) => {
        const ret = []
        for (let i = 0; i < items.length; i++) {
            ret[i] = null;
        }
        let col1pos = 0;
        let col2pos = pivot;
        for (let i = 0; i < items.length; i++) {

            ret[i] = (i % 2 === 0) ? items[col1pos++] : items[col2pos++];

        }
        return ret;
    }
    let col1order = 1;
    let col2order = pivot + 1;
    return (
        <Grid container>
            {reorder(children).map((i, index) =>
                <Grid item sm={6} key={"item" + index} >
                    <AnimatedMount onEnterAnimation={index % 2 === 0 ? AnimationType.slideInLeft : AnimationType.slideInRight} >
                        <BulletItem number={index % 2 === 0 ? col1order++ : col2order++}> {i}  </BulletItem>
                    </AnimatedMount>
                </Grid>
            )}
        </Grid>

    )
}

export default withWidth()(ResponsiveOrderedList);

interface IResponsiveOrderedListItemProps {

    children?: React.ReactNode;

}
