import React from 'react';

import { Content } from './layout/Content';
import { colors } from "./theme";
import { Helmet } from 'react-helmet';


export const Reklamace: React.FC = () => {

    return (
        <main style={{ textAlign: "justify" }}>
            <Helmet>
                <title>Reklamační řád ZÁBAVNÁ MĚSTA s.r.o.</title>
                <link rel="canonical" href={`https://www.zabavnamesta.cz/reklamace`} />
            </Helmet>
            <Content background={colors.sections.section2.bgColor}>
                <h1>Reklamační řád ZÁBAVNÁ MĚSTA s.r.o.</h1>

                <div>
                    <div>ZÁBAVNÁ MĚSTA s. r. o.</div>
                    <div>Bělkovice-Lašťany 686, 783 16</div>
                    <div>IČ 08969817</div>
                </div>

                <ol>
                    <li><p>Společnost ZÁBAVNÁ MĚSTA s.r.o. (dále jen “provozovatel”) odpovídá zákazníkovi za to, že podklady ke hře si může vyzvednout na konkrétním místě (instrukce jsou součástí emailového potvrzení úhrady ceny hry).</p></li>
                    <li><p>Provozovatel odpovídá za funkčnost aplikace s nápovědami a řešením jednotlivých her. </p></li>
                    <li><p>Provozovatel nenese odpovědnost za funkčnost telefonu, tabletu nebo jiného hardwarového zařízení, na kterém je spuštěna aplikace s nápovědami a řešením jednotlivých her.</p></li>
                    <li><p>Zákazník má právo uplatnit reklamaci a to písemně na adrese sídla provozovatele nebo emailem zaslaným na <a href="mailto:info@zabavnamesta.cz">info@zabavnamesta.cz</a></p></li>
                    <li><p>Zákazník je povinen reklamovat únikovou hru bez zbytečného odkladu poté, co zjistí, že je na únikové hře vada.</p></li>
                    <li><p>Provozovatel je povinen rozhodnout o reklamaci bez zbytečného odkladu, nejpozději však do 30 dnů od jejího podání.</p></li>
                    <li><p>Lhůta pro vyřízení reklamace běží od okamžiku uplatnění (oznámení) reklamace.</p></li>
                    <li><p>Je-li reklamace uznána za oprávněnou, má zákazník právo na úhradu účelně vynaložených nákladů spojených s uplatněním reklamace</p></li>
                    <li><p>U vady, která znamená nepodstatné porušení smlouvy (bez ohledu na to, jde-li o vadu odstranitelnou či neodstranitelnou), má zákazník nárok na odstranění vady anebo přiměřenou slevu z ceny hry.</p></li>
                    <li><p>Záruční doba se prodlužuje o dobu od uplatnění reklamace do jejího vyřízení nebo do doby, kdy bylo zákazníkovi umožněno si podklady vyzvednout. Dojde-li k výměně zboží nebo jeho části, uplatní se odpovědnost poskytovatele jako by šlo o koupi nového zboží nebo jeho části. </p></li>
                    <li><p>O vyřízení reklamace informuje provozovatel zákazníka prostřednictvím emailu.</p></li>
                </ol>


                <p> Tento reklamační řád je účinný ode dne 15. 4. 2020.</p>

            </Content>
        </main>
    )
}
