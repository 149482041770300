import React from 'react';
import { Content } from './layout/Content';
import { colors } from "./theme";
import { Helmet } from 'react-helmet';


export const Podminky: React.FC = () => {

    return (
        <main style={{ textAlign: "justify" }}>
            <Helmet>
                <title>Obchodní podmínky ZÁBAVNÁ MĚSTA s. r. o.</title>
                <link rel="canonical" href={`https://www.zabavnamesta.cz/obchodni-podminky`} />
            </Helmet>
            <Content background={colors.sections.section2.bgColor} >
                <h1>Obchodní podmínky ZÁBAVNÁ MĚSTA s. r. o.</h1>
                <div>
                    <div>ZÁBAVNÁ MĚSTA s. r. o. </div>
                    <div>Bělkovice-Lašťany 686, 783 16 </div>
                    <div>IČ 08969817 </div>
                </div>
                <h2>Úvodní ustanovení</h2>
                <ol>
                    <li><p>Tyto obchodní podmínky upravují vztahy vznikající a existující mezi ZÁBAVNÁ MĚSTA s.r.o. (dále jen “provozovatel”) a zákazníkem, který uzavřel s provozovatelem kupní smlouvu tím, že učinil objednávku šifrovací hry.</p></li>
                    <li><p>Kupní smlouvou se rozumí smlouva kupní ve smyslu §2079 zákona č. 89/2012 Sb., v platném znění.</p></li>
                    <li><p>Kupní smlouva je uzavírána prostřednictvím realizace objednávky na webových stránkách <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a>.</p></li>
                    <li><p>Tyto obchodní podmínky jsou nedílnou součástí kupní smlouvy uzavřené mezi provozovatelem a zákazníkem.</p></li>
                    <li><p>Zákazník prohlašuje, že před objednáním hry se seznámil s těmito obchodními podmínkami a dále s pravidly pro ochranu osobních údajů a reklamačním řádem.</p></li>
                    <li><p>Předmětem kupní smlouvy je produkt duševního vlastnictví, je proto zakázáno jakékoliv jeho šíření nebo poskytování třetím osobám bez souhlasu provozovatele.</p></li>
                    <li><p>Provozovatel si vyhrazuje právo změnit a/nebo aktualizovat znění těchto obchodních podmínek a to zveřejněním aktuálního znění na webových stránkách <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a></p></li>
                </ol>
                <h2>Bezpečnostní pokyny</h2>
                <ol>
                    <li><p>Zákazník včetně všech dalších účastníků hry (odlišných od zákazníka = kupujícího) hraje hru na svoji vlastní odpovědnost.</p></li>
                    <li><p>Zákazník a další účastnící dodržují všechny bezpečnostní a provozní pravidla. Hry se hrají např. v běžném provozu, na veřejných komunikacích, kde je zákazník povinen dodržovat standardní bezpečnostní pravidla.</p></li>
                    <li><p>Provozovatel nenese žádnou odpovědnost za zranění či škody způsobené zákazníkovi (včetně dalších účastníků) nebo škody způsobené třetím stranám zákazníkem (včetně dalších účastníků) během hraní hry.</p></li>
                    <li><p>Účastníci hry mladší 15 let musí být v průběhu hry doprovázeni rodiči či jiným zletilým zástupcem nezletilého účastníka.</p></li>
                    <li><p>V informacích obdržených od provozovatele může zákazník najít informace o produktech nebo službách třetích osob. Tyto informace jsou pouze doporučením a vyjádřením názoru k danému tématu.</p></li>
                </ol>
                <h2>Pravidla koupě a hraní her</h2>
                <ol>
                    <li><p>Hru je možné si objednat pouze prostřednictvím eshopu dostupného na webových stránkách <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a>.</p></li>
                    <li><p>Zákazník je povinen před objednáním hry vyplnit objednávkový formulář, za správnost údajů odpovídá zákazník.</p></li>
                    <li><p>Odeslaná objednávka je právně závazná, provozovateli a zákazníkovi vznikají vzájemná práva a povinnosti, tj. provozovatel je povinen po uhrazení ceny hry poskytnout podklady ke hře, zákazník je povinen uhradit cenu hry.</p></li>
                    <li><p>Cena hry je zveřejněna na webových stránkách <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a>. Provozovatel není plátcem DPH. Cena hry je konečná.</p></li>
                    <li><p>Po úhradě ceny hry zašle provozovatel zákazníkovi fakturu, která slouží jako doklad o zakoupeném zboží.</p></li>
                    <li><p>Úhrada ceny je provedena prostřednictvím platební brány, která umožňuje zákazníkovi vybrat si preferovaný způsob úhrady.</p></li>
                    <li><p>Podklady ke hře si zákazník vyzvedne dle instrukcí obdržených od provozovatele zaslaných emailem po uhrazení hry na výdejním místě (při osobním převzetí) nebo je mu herní balíček zaslán na adresu, bylo-li zvolena možnost zaslání balíčku na adresu. Zaslání balíčku je zpoplatněno, cena je sdělena kupujícímu před zvolením způsobu převzetí.</p></li>
                    <li><p>Hru je možné hrát ve lhůtě do 6 měsíců od úhrady ceny.</p></li>
                    <li><p>Provozovatel si vyhrazuje právo zrušit jakoukoliv hru s plnou náhradou zákazníkovi uhrazené ceny.</p></li>
                </ol>
                <h2>Ochrana spotřebitele</h2>
                <ol>

                    <li><p>Je-li zákazník v postavení spotřebitele, je provozovatel povinen dodržet ustanovení §1810 a násl. zákona č. 89/2012 Sb., upravující spotřebitelské smlouvy. Tato ustanovení upravují zejména informační povinnosti provozovatele a právo na odstoupení od smlouvy. Realizace práva na odstoupení je předmětem samostatného článku těchto obchodních podmínek.</p></li>
                    <li><p>V případě porušení povinností na straně provozovatele je zákazník v postavení spotřebitele oprávněn obrátit se na Českou obchodní inspekci prostřednictvím místně příslušného krajského inspektorátu, příp. elektronicky zde: <a href="https://www.coi.cz/podatelna">https://www.coi.cz/podatelna</a>.</p></li>
                    <li><p>Pokud mezi zákazníkem a provozovatelem vznikl spor ohledně práv a povinností ze smlouvy uzavřené přes internet, může zákazník podat návrh na zahájení mimosoudního řešení spotřebitelského sporu (ADR) u ČOI. Bližší informace k mimosoudnímu řešení spotřebitelského sporu naleznete zde: <a href="https://www.coi.cz/informace-o-adr">https://www.coi.cz/informace-o-adr</a>.</p></li>
                </ol>
                <h2>Odstoupení od smlouvy</h2>
                <ol>
                    <li><p>Zákazník je oprávněn od smlouvy odstoupit do 14 dnů od úhrady ceny hry.</p></li>
                    <li><p>Zákazník je oprávněn od smlouvy odstoupit rovněž kdykoliv před úhradou ceny hry.</p></li>
                    <li><p>Odstoupí-li zákazník od smlouvy, je povinen toto odstoupení provozovateli oznámit emailem zaslaným na adresu <a href="mailto:info@zabavnamesta.cz">info@zabavnamesta.cz</a>. Formulář pro odstoupení od smlouvy je zveřejněn na webových stránkách <a href="https://www.zabavnamesta.cz">www.zabavnamesta.cz</a>.</p></li>
                    <li><p>Provozovatel je povinen v případě odstoupení od smlouvy (viz výše) vrátit částku odpovídající ceně hry do 14 dnů od oznámení odstoupení od smlouvy.</p></li>
                    <li><p>Provozovatel je oprávněn od smlouvy odstoupit kdykoliv před vyzvednutím hry, pokud není objektivně schopný z důvodu na straně třetích osob umožnit zákazníkovi vyzvednout si podklady ke hře ve lhůtě přiměřené okolnostem a/nebo pokud vyjde najevo, že zákazník porušil uzavřenou smlouvu. Provozovatel oznámí odstoupení emailem zaslaným zákazníkovi a ve lhůtě 14 dnů od tohoto oznámení provozovatel vrátí zákazníkovi částku odpovídající ceně hry. </p></li>
                </ol>

                <p>Tyto obchodní podmínky jsou účinné ode dne 15. 4. 2020.</p>













            </Content>
        </main>
    )
}