

import React from 'react';
import { colors } from "../theme";
import { DifficultyLevel } from "./Game";
import { Box } from "@material-ui/core";
import  StarIcon  from "../img/TrophyIcon"
interface IBadgeProps {
    position: number
    circleSize?: number;
    iconSize?: number;
}

interface ILevelColor {
    bgColor: string;
    color: string;
    hoverColor: string;
}
export const Badge: React.FC<IBadgeProps> = ({ position, circleSize = 28, iconSize = 16 }) => {
    const getColor = (position: number) => {
        switch (position) {
            case 0: return colors.board.places.first;
            case 1: return colors.board.places.second;
            case 2: return colors.board.places.third;
            default:
                return colors.board.places.empty;
        }
    }
    const getTooltip = (level: DifficultyLevel) => {
        switch (level) {
            case 0: return "První místo";
            case 1: return "Druhé místo";
            case 2: return "Třetí místo";
            default:
                return ""
        }
    }
    return (
        <Box title={getTooltip(position)} display="flex" justifyContent="flex-end" alignSelf="center" style={{width:50}} >
            {position <= 2 && <Box display="flex"><StarIcon fill={getColor(position)} height={iconSize} /></Box>}
            <Box display="flex" alignSelf="center" textAlign="right" style={{marginLeft:5,marginRight:15}}>{position+1}.</Box>
        </Box>
    )
}
