import React from "react";
interface IconProps{
    fill:string;
    class?:string;
    className?: string;
    height:number;
    hoverFill?:string;
}


const FunIcon = (props:IconProps) =>(
    <svg fill={props.fill} className={props.class} viewBox="0 0 448.04 448.04" width={props.height} height={props.height} >
        <path id="XMLID_763_" d="M172.592,297.563v15.95c0,6.679,2.47,12.792,6.543,17.474v57.021c0,13.195,10.735,23.929,23.93,23.929
	h41.914c13.194,0,23.929-10.734,23.929-23.929v-57.021c4.073-4.682,6.543-10.794,6.543-17.473v-15.949
	c42.43-19.901,69.521-62.236,69.521-109.495c0-66.691-54.258-120.948-120.95-120.948c-66.691,0-120.949,54.257-120.949,120.948
	C103.072,235.328,130.162,277.663,172.592,297.563z M250.908,388.007c0,3.214-2.715,5.929-5.929,5.929h-41.914
	c-3.214,0-5.93-2.715-5.93-5.929V376.05h29.804c4.971,0,9-4.029,9-9s-4.029-9-9-9h-29.804v-17.968
	c0.696,0.055,1.399,0.083,2.109,0.083H248.8c0.71,0,1.413-0.028,2.108-0.083V388.007z M224.021,85.121
	c56.767,0,102.95,46.183,102.95,102.948c0,41.967-25.096,79.372-63.935,95.293c-3.379,1.385-5.586,4.675-5.586,8.328v21.823
	c0,4.771-3.881,8.651-8.651,8.651h-49.556c-4.771,0-8.652-3.881-8.652-8.651v-21.824c0-3.652-2.207-6.942-5.586-8.328
	c-38.838-15.92-63.933-53.325-63.933-95.292C121.072,131.303,167.255,85.121,224.021,85.121z M215.018,33.955V9c0-4.971,4.029-9,9-9
	s9,4.029,9,9v24.955c0,4.971-4.029,9-9,9S215.018,38.926,215.018,33.955z M215.021,117.101c0-4.971,4.029-9,9-9
	c46.61,0,84.531,37.92,84.531,84.53c0,4.971-4.029,9-9,9s-9-4.029-9-9c0-36.685-29.846-66.53-66.531-66.53
	C219.051,126.101,215.021,122.071,215.021,117.101z M351.935,52.982c3.515,3.515,3.515,9.213,0,12.728l-16.604,16.604
	c-1.758,1.757-4.062,2.636-6.364,2.636c-2.304,0-4.606-0.878-6.364-2.636c-3.515-3.515-3.515-9.213,0.001-12.728l16.604-16.604
	C342.723,49.467,348.421,49.468,351.935,52.982z M404.92,180.898c0,4.971-4.029,9-9,9h-19.873c-4.971,0-9-4.029-9-9s4.029-9,9-9
	h19.873C400.89,171.898,404.92,175.928,404.92,180.898z M345.268,411.505c0,12.425-12.996,21.653-39.731,28.211
	c-21.882,5.368-50.83,8.324-81.513,8.324c-32.522,0-63.798-3.439-85.809-9.435c-23.848-6.497-35.44-15.361-35.44-27.101
	c0-13.833,16.051-23.732,49.069-30.266c4.871-0.966,9.61,2.205,10.576,7.082c0.965,4.876-2.206,9.611-7.082,10.576
	c-23.037,4.559-32.005,10.175-34.19,12.58c1.394,1.502,6.357,5.556,21.798,9.762c20.534,5.594,50.085,8.802,81.077,8.802
	c27.058,0,52.965-2.419,72.95-6.813c21.865-4.807,28.298-10.006,29.92-11.752c-2.188-2.406-11.156-8.021-34.191-12.579
	c-4.876-0.965-8.047-5.7-7.082-10.576c0.966-4.875,5.699-8.046,10.576-7.082C329.217,387.772,345.268,397.672,345.268,411.505z
	 M112.71,82.317L96.105,65.711c-3.515-3.515-3.515-9.213,0-12.728c3.515-3.515,9.213-3.515,12.728,0l16.605,16.605
	c3.515,3.515,3.515,9.213,0,12.728c-1.757,1.758-4.061,2.636-6.364,2.636S114.468,84.075,112.71,82.317z M80.996,180.898
	c0,4.971-4.029,9-9,9H52.12c-4.971,0-9-4.029-9-9s4.029-9,9-9h19.875C76.967,171.898,80.996,175.928,80.996,180.898z"/>
    </svg>
    )

    export default FunIcon;