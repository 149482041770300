import React from "react";
import { Card, CardMedia, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../theme";
import { ReactNode } from "react";
import clsx from "clsx";

interface IProductBoxProps {
  isLoading?: boolean;
  iconComponent?: ReactNode;
  actionArea?: ReactNode;
  content: ReactNode;
  ribbonComponent?: ReactNode;
  imageUrl: string;
  title?: string;
  onClick?: () => void;
}

export const ProductBox: React.FC<IProductBoxProps> = ({ isLoading = false, iconComponent, imageUrl, title, ribbonComponent, actionArea, content, onClick }) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Card className={clsx(classes.main, { [classes.hover]: !!onClick })} raised={true}>
        <CardMedia className={classes.mediaBg} image={imageUrl} title={title} />
        <CardMedia className={classes.media}>
          {iconComponent}
          {!isLoading && ribbonComponent}
        </CardMedia>
        <CardContent>{content}</CardContent>
        {actionArea}
      </Card>
    </div>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    textAlign: "left",
    fontSize: "1em",
    padding: 10,
    display: "flex",
    position: "relative",
    marginTop: 10,
    height: "100%",
  },
  hover: {
    cursor: "pointer",
  },

  main: {
    height: "100%",
    flexGrow: 1,
    "&:hover": {
      boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.8),0px 8px 10px 1px rgba(0,0,0,0.74),0px 3px 14px 2px rgba(0,0,0,0.72);",
    },
  },

  media: {
    display: "flex",
    justifyContent: "center",
  },
  mediaBg: {
    height: 250,
  },
}));
