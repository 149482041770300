import React from "react";
interface IconProps {
    fill: string;
    class?: string;
	className?: string;
	height: number;
}


const EmptyIcon = (props: IconProps) => (
    <svg fill={props.fill} height={props.height} className={props.class} version="1.1" x="0px" y="0px"  viewBox="0 0 558.964 558.964">
<g>
	<g>
		<rect y="383.987" width="171.02" height="131.846"/>
		<rect x="387.943" y="428.987" width="171.021" height="86.843"/>
		<rect x="186.323" y="329.513" width="186.32" height="186.32"/>
		<path d="M427.5,119.334c3.137-26.638-16.034-48.752-39.887-56.384c-2.602-0.832-4.483-3.513-4.214-6.23
			c0.297-2.999,0.505-5.888,0.621-8.645c0.113-2.729-2.108-4.945-4.841-4.945H179.79c-2.732,0-4.954,2.215-4.838,4.945
			c0.116,2.757,0.321,5.646,0.618,8.645c0.27,2.717-1.613,5.401-4.213,6.233c-23.853,7.632-43.027,29.747-39.887,56.384
			c1.775,15.074,7.76,29.884,20.227,39.376c7.02,5.346,14.458,9.367,22.42,13.097c3.647,1.601,7.313,3.158,10.998,4.676
			c7.58,3.173,11.359,6.276,16.588,10.744c2.078,1.775,2.075,1.53-0.609,1.016c-8.611-1.64-15.937,11.659-7.372,18.847
			c8.685,7.292,18.712,5.96,27.072-1.114c2.531-2.142,4.116-4.672,4.97-7.402c0.817-2.607,2.09-3.075,3.767-0.921
			c9.006,11.564,18.81,20.744,29.171,26.179c2.421,1.27,4.48,4.312,4.48,7.044v31.053c0,0.952,0.095,1.879,0.266,2.778
			c0.288,1.519-1.472,2.696-4.204,2.696h-20.527c-6.756,0-12.23,5.475-12.23,12.23v19.63c0,6.757,5.477,12.231,12.23,12.231h81.537
			c6.757,0,12.231-5.475,12.231-12.231v-19.63c0-6.756-5.478-12.23-12.231-12.23h-20.526c-2.732,0-4.492-1.179-4.204-2.696
			c0.171-0.899,0.267-1.826,0.267-2.778v-31.056c0-2.733,2.059-5.774,4.477-7.044c10.361-5.435,20.165-14.614,29.171-26.178
			c1.677-2.154,2.949-1.686,3.767,0.921c0.854,2.729,2.438,5.26,4.97,7.402c8.359,7.075,18.388,8.406,27.071,1.114
			c8.562-7.188,1.239-20.487-7.371-18.846c-2.684,0.511-2.684,0.759-0.609-1.016c5.227-4.468,9.006-7.57,16.589-10.744
			c3.684-1.518,7.347-3.075,10.994-4.676c7.965-3.73,15.401-7.751,22.424-13.097C419.737,149.218,425.723,134.407,427.5,119.334z
			 M204.457,161.944c-11.71-6.209-25.967-9.85-36.398-16.374c-27.069-16.934-17.687-51.38,6.854-62.029
			c2.506-1.086,5.104,0.496,5.716,3.158c5.401,23.607,14.357,49.838,25.891,73.302C207.725,162.449,206.871,163.224,204.457,161.944
			z M298.105,188.814h-19.327V92.565h-0.453l-25.698,13.865l-3.859-15.235l32.277-17.283h17.06V188.814z M390.905,145.567
			c-10.432,6.524-24.688,10.165-36.398,16.374c-2.414,1.279-3.265,0.505-2.06-1.946c11.533-23.464,20.487-49.694,25.891-73.299
			c0.609-2.662,3.207-4.244,5.713-3.158C408.593,94.19,417.972,128.636,390.905,145.567z"/>
	</g>
</g>

</svg >
    )

export default EmptyIcon;