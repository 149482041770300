import React, { useState } from 'react';
import { Box, SwipeableDrawer, AppBar, Toolbar, Grid, Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { colors } from "../theme";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

interface IToolbarProps {

    logo?: React.ReactNode;
    children?: React.ReactNode;
}
interface IResponsiveToolbarProps extends IToolbarProps {

    logo?: React.ReactNode;
    children?: React.ReactNode;
    width: Breakpoint;
}

const ResponsiveToolbar: React.FC<IResponsiveToolbarProps> = ({ logo, children, width }) => {
    const fullWidth = isWidthUp('md', width);
    return fullWidth ? <FullWidthToolbar {...{ logo, children }} /> : <DrawerToolbar {...{ logo, children }} />
}

const FullWidthToolbar: React.FC<IToolbarProps> = ({ logo, children }) => {
    const classes = useStyles({});
    return (
        <AppBar className={classes.appbar} >
            <Toolbar>
                <Container>

                    <Box display="flex" flexWrap="no-wrap"  >
                        <Box flexGrow={0}>
                            {logo}
                        </Box>
                        <Box display="flex" className={classes.links} justifyContent="flex-end" alignItems="center" flexGrow={1}>

                            {children}
                        </Box>
                    </Box>

                </Container>

            </Toolbar>
        </AppBar>)
}
const DrawerToolbar: React.FC<IToolbarProps> = ({ logo, children }) => {
    const classes = useStyles({});
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <React.Fragment>
            <AppBar className={classes.appbar} >
                <Toolbar>
                    <Container>
                        <Box display="flex" flexWrap="no-wrap"  >
                            <Box flexGrow={0}>
                                {logo}
                            </Box>
                            <Box display="flex" className={classes.links} justifyContent="flex-end" alignItems="center" flexGrow={1}>

                                <MenuIcon onClick={() => setIsOpen(!isOpen)} className={classes.menuIcon} />
                            </Box>
                        </Box>
                    </Container>

                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
            >

                <div
                    tabIndex={0}
                    role="button"
                    onClick={() => setIsOpen(false)}
                    onKeyDown={() => setIsOpen(false)}>

                    {/* <List>
                        <ListItem key={1} button divider> Option 1 </ListItem>
                        <ListItem key={2} button divider> Option 2 </ListItem>
                        <ListItem key={3} button divider> Option 3 </ListItem>
                    </List> */}

                    <Grid container item className={classes.links} style={{ flexDirection: "column" }} >
                        {children}
                    </Grid>

                </div>
            </SwipeableDrawer>
        </React.Fragment>
    )
}
const useStyles = makeStyles(() => ({
    appbar: {
        //transition: "background-color 0.5s ease",
        //marginTop:"-250px",
        position: "relative",
        //backgroundColor: "rgb(88,151,0)",
        //background: "linear-gradient(41deg, rgba(88,151,0,1) 50%, rgba(124,205,43,1) 100%)"
        background: colors.header.appbar.bgColor,
        color: colors.header.appbar.color,
        boxShadow: "inset 0 -1px 0 0 rgba(0, 0, 0, 0.20)",

    },
    links: {
        float: "right"
    },
    menuIcon: {

    },
}));

export default withWidth()(ResponsiveToolbar);