

import React, { ReactElement } from 'react';
import { CircleIcon } from './CircleIcon';
interface ICustomGameIconProps {
    children: ReactElement;
    title: string;
    bgColor: string;
    hoverColor: string;
    size?: number;
}


export const CustomGameIcon: React.FC<ICustomGameIconProps> = ({ children, bgColor, hoverColor, title ,size = 28 }) => {
  
    return (
        <CircleIcon size={size} title={title} bgColor={bgColor} hoverColor={hoverColor} >
            {children}
        </CircleIcon>
    )
}
