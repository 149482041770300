

import React, { useState, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../theme";
import { config } from "../config";
import { AnimatedMount, AnimationType } from "./AnimatedMount";
import { Game, GameItem } from "./Game";
import NapoleonIcon from '../img/NapoleonIcon';
import BeerIcon from '../img/BeerIcon';
import JokerIcon from '../img/JokerIcon';
import OstravaIcon from '../img/OstravaIcon';
import FrydekIcon from '../img/FrydekIcon';
import CartIcon from '../img/CartIcon';
import HistoryIcon from '../img/HistoryIcon';

import ZabavnaMestaIcon from '../img/ZabavnaMestaIcon';

import axios from "axios";
import { Error } from "./Error";
import { CityType } from 'src/types';
import { useCallback } from 'react';


interface IOfferedGamesProps {

    children?: React.ReactNode;
    city: CityType;
}

export const OfferedGames: React.FC<IOfferedGamesProps> = ({city}) => {
    const classes = useStyles({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [games, setGames] = useState<GameItem[] | null>(null);
    const [, setError] = useState<string | null>(null);

    const loadData = useCallback(async () => {

        try {
            const result = await axios.get(`${config.gamesUrl}/${city}`);
            if (result?.data?.length) {
                //console.log("Obtained data", result.data);
                setGames(result.data);
            }
            else {
                console.error("Failed to obtain games offer", result);
                setError("Nepodařilo se načíst data");
            }
        }
        catch (e) {
            console.error("Failed to obtain games offer");
            setError("Nepodařilo se načíst data");
        }
        finally {
            setIsLoading(false);

        }
    }, [city]);

    useEffect(() => {
        loadData();
        return () => {
            setGames(null);
        };

    }, [loadData, city])
    const getAnimation = (index: number) => {
        if (index % 3 === 0) return AnimationType.slideInLeft;
        else if (index % 3 === 1) return AnimationType.slideInUp;
        else return AnimationType.slideInRight;
    }

    const renderIcon = (id: string | undefined): React.ReactNode => {
       if (id != null){
        return <img className={classes.icon} src={`https://admin.zabavnamesta.cz/Content/Images/Icons/Games/${id}.png`} alt=""/>
       }
        else
            return (<ZabavnaMestaIcon fill={colors.game.icon.color}/>)

    }

    const renderError = () => {
        return (<Error />);
    }
    const renderContent = () => {
        return (
            <Grid container alignItems="stretch">
                {games!.map((g, index) =>
                    <Grid className={classes.game} item xs={12} sm={6} md={4} style={{ display: "flex" }} key={index}>
                        <AnimatedMount onEnterAnimation={getAnimation(index)} duration={"1.3s"} display="flex">
                            <Game isLoading={isLoading} game={g} icon={renderIcon(g.GameId)} />
                        </AnimatedMount>
                    </Grid>
                )}
            </Grid>

        );
    }
    const renderSkeletons = () => {
        return (
            <Grid container alignItems="stretch">
                <Grid className={classes.game} item xs={12} sm={6} md={4} style={{ display: "flex" }}>
                    <AnimatedMount onEnterAnimation={AnimationType.slideInLeft} duration={"1.3s"} display="flex">
                        <Game isLoading={isLoading} />
                    </AnimatedMount>
                </Grid>
                <Grid className={classes.game} item xs={12} sm={6} md={4} style={{ display: "flex" }}>
                    <AnimatedMount onEnterAnimation={AnimationType.slideInUp} duration={"1.3s"} display="flex">
                        <Game isLoading={isLoading} />
                    </AnimatedMount>
                </Grid>

                <Grid className={classes.game} item xs={12} sm={6} md={4} style={{ display: "flex" }}>
                    <AnimatedMount onEnterAnimation={AnimationType.slideInRight} duration={"1.3s"} display="flex">
                        <Game isLoading={isLoading} />
                    </AnimatedMount>
                </Grid>
            </Grid>
        );

    }
    const render = () => {
        if (isLoading)
            return renderSkeletons();
        else if (!isLoading && games?.length)
            return renderContent();
        else
            return renderError();
    }

    return (
        <React.Fragment>
            {render()}
        </React.Fragment>
    );

}


const useStyles = makeStyles(theme => ({
    root: {

    },
    game: {
        marginTop:40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },
    icon: {
        height: 50,
        lineHeight: 50,
        width: 50,
    }
}));