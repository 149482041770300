import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { colors } from "../theme";

interface IStyleProps {
    size?: number;
    hoverColor?: string;
    bgColor?: string;
    color?: string;
}

interface ICircleIconProps extends IStyleProps {

    title?: string;
    children?: React.ReactNode;
    style?: CSSProperties;
}

export const CircleIcon: React.FC<ICircleIconProps> = ({
    children,
    title,
    size = 50,
    style,
    color = colors.game.icon.color,
    bgColor = colors.bullet.icon.bgColor,
    hoverColor = colors.bullet.icon.hoverColor,
}) => {
    const classes = useStyles({ size, color, bgColor, hoverColor });
    return (
        <div className={classes.root} title={title} style={style}>
            <div className={classes.icon}>
                {children}
            </div>
        </div>
    )
}



const useStyles = makeStyles<Theme, IStyleProps>(() => ({
    root: {
        fontSize: "1em",
        display: "flex",
        alignItems: "center",
        cursor:"default",
        margin:2,
    },
    icon: props => ({
        height: props.size,
        width: props.size,
        background: props.bgColor,
        color: props.color,
        borderRadius: "50%",

        flexShrink: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor:"default",
        transition: "fill 500ms linear",
        "& svg:hover": {
            //fill: props.hoverColor,
            transition: "fill 500ms linear",
        },
        "&:hover": {
            background: props.hoverColor,
            transition: "fill 500ms linear",

        }


    }),
}));