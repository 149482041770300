

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import { CircleIcon } from './CircleIcon';

interface IBulletItemProps {
    number?: number;
    children?: React.ReactNode;
}

export const BulletItem: React.FC<IBulletItemProps> = ({ children, number }) => {
    const classes = useStyles({});

    return (
        <div className={classes.root}>

            <CircleIcon>
                {number ? <div className={classes.number}>{number < 10 ? ("0"+number): number}</div> : <KeyboardArrowRightRoundedIcon />}
            </CircleIcon>
            <div className={classes.content}>
                {children}

            </div>
        </div>
    )
}
const useStyles = makeStyles(() => ({
    root: {
        fontSize: "1em",
        padding: "10px 0px",
        display: "flex",
        alignItems: "center",
        cursor: "default",
    },

    number: {

    },
    content: {
        padding: "0px 15px",
    }


}));