import React from "react";
interface IconProps {
    fill: string;
    class?: string;
    className?: string;
    height: number;
    hoverFill?:string;
}


const ChallengeIcon = (props: IconProps) => (
    <svg fill={props.fill} className={props.class} viewBox="0 0 429.987 429.987" width={props.height} height={props.height} >
        <path d="M72.198,338.114c-14.23,0-25.807,11.577-25.807,25.806c0,14.23,11.577,25.807,25.807,25.807
	c14.229,0,25.806-11.577,25.806-25.807C98.004,349.69,86.427,338.114,72.198,338.114z M72.198,371.727
	c-4.305,0-7.807-3.502-7.807-7.807c0-4.304,3.502-7.806,7.807-7.806c4.304,0,7.806,3.502,7.806,7.806
	C80.004,368.225,76.502,371.727,72.198,371.727z M414.857,0H345.53c-4.971,0-9,4.029-9,9v32.936
	c-16.637,2.276-31.321,10.768-41.626,23.053H112.665c-28.382,0-55.114,11.102-75.274,31.261
	C17.232,116.412,6.13,143.145,6.13,171.524s11.102,55.113,31.262,75.274c20.159,20.158,46.892,31.26,75.273,31.26l192.396-0.001
	c5.737,0,11.174,2.278,15.313,6.418c4.137,4.135,6.415,9.572,6.415,15.312c0,5.737-2.279,11.176-6.417,15.313
	c-4.137,4.136-9.574,6.415-15.311,6.415H122.823c-12.129-14.456-30.324-23.662-50.627-23.662c-36.429,0-66.066,29.637-66.066,66.066
	c0,36.429,29.637,66.067,66.066,66.067c20.306,0,38.5-9.206,50.629-23.665h182.236c28.379,0,55.112-11.101,75.274-31.26
	c2.439-2.44,8.287-9.108,8.673-9.78c14.646-18.667,22.586-41.492,22.586-65.495c0-28.38-11.102-55.113-31.261-75.274
	c-20.161-20.159-46.894-31.261-75.273-31.261h-13.646c-0.027,0-79.275,0-79.275,0c-0.077-0.001-0.154-0.001-0.23,0l-99.245,0.001
	c-5.736,0-11.175-2.279-15.313-6.417c-4.137-4.136-6.415-9.575-6.415-15.313c0-3.488,0.842-6.866,2.432-9.908
	c0.118-0.198,0.228-0.4,0.329-0.605c0.976-1.731,2.2-3.345,3.653-4.798c4.14-4.139,9.579-6.417,15.314-6.417h182.239
	c12.129,14.456,30.324,23.664,50.626,23.664c36.429,0,66.065-29.637,66.065-66.066c0-5.772-0.749-11.51-2.226-17.052
	c-1.28-4.803-6.211-7.658-11.014-6.379c-4.803,1.28-7.659,6.211-6.379,11.014c1.075,4.031,1.619,8.209,1.619,12.417
	c0,26.504-21.562,48.066-48.065,48.066c-26.504,0-48.067-21.563-48.067-48.066c0-23.429,16.849-42.996,39.067-47.222v47.222
	c0,4.971,4.029,9,9,9s9-4.029,9-9V65.894h60.327c4.971,0,9-4.029,9-9V9C423.857,4.029,419.828,0,414.857,0z M72.196,411.987
	c-26.503,0-48.066-21.563-48.066-48.067c0-26.503,21.563-48.066,48.066-48.066c26.505,0,48.069,21.563,48.069,48.066
	C120.265,390.424,98.701,411.987,72.196,411.987z M203.025,388.322h-69.43c3.012-7.552,4.67-15.788,4.67-24.402
	c0-8.616-1.657-16.851-4.671-24.404h69.431V388.322z M282.374,388.322h-61.349v-48.806h61.349V388.322z M367.608,362.334
	c-16.762,16.758-38.974,25.988-62.546,25.988h-4.688v-48.806h4.688c10.545,0,20.502-4.15,28.038-11.686
	c0.403-0.403,0.796-0.813,1.179-1.229l34.52,34.52C368.406,361.528,368.009,361.933,367.608,362.334z M393.595,299.788
	c0,16.893-4.741,33.088-13.596,47.08l-36.616-36.616c0.927-3.373,1.405-6.886,1.405-10.465c0-3.529-0.465-6.992-1.365-10.32
	l36.647-36.646C388.88,266.784,393.595,282.939,393.595,299.788z M300.374,211.251h4.688c23.572,0,45.785,9.23,62.545,25.989
	c0.432,0.432,0.859,0.868,1.281,1.307l-34.522,34.521c-0.41-0.448-0.832-0.888-1.264-1.321c-7.537-7.539-17.495-11.69-28.04-11.689
	h-4.688V211.251z M221.025,211.251h61.349v48.807h-61.349V211.251z M146.967,211.251h56.058v48.807h-56.058V211.251z M24.13,171.523
	c0-17.531,5.104-34.309,14.615-48.652l36.201,36.199c-1.322,3.972-2.009,8.165-2.009,12.455c0,4.611,0.793,9.11,2.317,13.343
	l-36.027,36.027C29.406,206.394,24.13,189.349,24.13,171.523z M112.658,211.251c0.002,0,0.005,0,0.007,0h16.302v48.807h-16.302
	c-23.249,0-45.173-8.976-61.848-25.298l34.509-34.51C92.776,207.353,102.443,211.252,112.658,211.251z M128.967,131.794h-16.302
	c-10.498,0-20.417,4.115-27.946,11.593l-34.506-34.504c16.749-16.699,38.922-25.895,62.452-25.895h16.302V131.794z M203.025,131.794
	h-56.058V82.989h56.058V131.794z M284.133,131.794h-63.108V82.989h63.108c-3.013,7.554-4.67,15.787-4.67,24.403
	S281.12,124.241,284.133,131.794z M405.857,47.894H354.53V18h51.327V47.894z"/>
    </svg>
)

export default ChallengeIcon;