import React, { useRef, useState } from "react";
import { InView } from 'react-intersection-observer';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export enum AnimationType {
    slideInLeft = "slideInLeft",
    slideInRight = "slideInRight",
    slideInUp = "slideInUp",
    fadeIn = "fadeIn",
    fadeInDown = "fadeInDown",
    fadeInLeft = "fadeInLeft",
    fadeInRight = "fadeInRight",
    fadeInUp = "fadeInUp",
    lightSpeedIn = "lightSpeedIn",
    rotateIn = "rotateIn",
    rotateInDownLeft = "rotateInDownLeft",
    rotateInDownRight = "rotateInDownRight",
    rotateInUpLeft = "rotateInUpLeft",
    rotateInUpRight = "rotateInUpRight",
    rollIn = "rollIn",
    zoomIn = "zoomIn",
    zoomInDown = "zoomInDown",
    zoomInLeft = "zoomInLeft",
    zoomInRight = "zoomInRight",
    zoomInUp = "zoomInUp",
}

interface IAnimatedMountProps {
    onEnterAnimation?: AnimationType;
    onLeaveAnimation?: AnimationType;
    duration?: string;
    children?: React.ReactNode;
    display?: "flex" | "block";
}
interface IStyleProps {
    duration: string;

}
export const AnimatedMount: React.FC<IAnimatedMountProps> = ({ children, onLeaveAnimation, onEnterAnimation, duration = "1.5s", display }) => {
    const classes = useStyles({ duration });
    const wrapper = useRef<HTMLDivElement>(null);
    const [rendered, setRendered] = useState<boolean>(false);
    let prev = 0;
    const onChange = (inView: boolean, entry: IntersectionObserverEntry) => {
        let isAbove = !prev || entry.boundingClientRect.y <= prev;

        prev = entry.boundingClientRect.y;

        if (inView) {

            if (!rendered && isAbove && onEnterAnimation && wrapper.current) {
                wrapper.current.className = `${classes.animation} ${classes[onEnterAnimation]}`;//`;
            }
            setRendered(true);
        }
        else {
            if (onLeaveAnimation && wrapper.current) {
                wrapper.current.className = `${classes.animation} ${classes[onLeaveAnimation]}`;//`;
            }

        }
    }
    return (
        <InView as="div" onChange={onChange} className={display === "flex" ? classes.flex : classes.root}>
            <div ref={wrapper} >
                {children}
            </div>
        </InView>
    )
}


const useStyles = makeStyles<Theme, IStyleProps>(() => ({
    animation: props => ({
        animationDuration: props.duration,
    }),
    root: {

    },
    flex: {
        display: "flex",
        flexGrow: 1,
        "& > div": {
            flexGrow: 1,

        }
    },
    "@keyframes slideInLeft": {
        from: {
            transform: "translate3d(-100%,0,0)",
            visibility: "visible"
        },

        to: {
            transform: "translate3d(0,0,0)"
        }
    },
    slideInLeft: {
        animationName: "$slideInLeft",

    },
    "@keyframes slideInRight": {
        from: {
            transform: "translate3d(100%,0,0)",
            visibility: "visible"
        },

        to: {
            transform: "translate3d(0,0,0)"
        }
    },
    slideInRight: {
        animationName: "$slideInRight",

    },
    "@keyframes slideInUp": {
        from: {
            transform: "translate3d(0,100%,0)",
            visibility: "visible"
        },

        to: {
            transform: "translate3d(0,0,0)"
        }
    },
    slideInUp: {
        animationName: "$slideInUp",

    },

    "@keyframes slideInDown": {
        from: {
            transform: "translate3d(0,-100%,0)",
            visibility: "visible"
        },

        to: {
            transform: "translate3d(0,0,0)"
        }
    },
    slideInDown: {
        animationName: "$slideInDown",

    },














    "@keyframes fadeIn": {
        from: {
            opacity: 0
        },

        to: {
            opacity: 1
        }
    },

    fadeIn: {
        animationName: "$fadeIn",
    },

    "@keyframes fadeInDown": {
        from: {
            opacity: 0,
            transform: "translate3d(0,-100%,0)"
        },

        to: {
            opacity: 1,
            transform: "none"
        }
    },

    fadeInDown: {
        animationName: "$fadeInDown"
    },

    "@keyframes fadeInLeft": {
        from: {
            opacity: 0,
            transform: "translate3d(-100%,0,0)"
        },

        to: {
            opacity: 1,
            transform: "none"
        }
    },

    fadeInLeft: {
        animationName: "$fadeInLeft"
    },

    "@keyframes fadeInRight": {
        from: {
            opacity: 0,
            transform: "translate3d(100%,0,0)"
        },

        to: {
            opacity: 1,
            transform: "none"
        }
    },

    fadeInRight: {
        animationName: "$fadeInRight"
    },

    "@keyframes fadeInUp": {
        from: {
            opacity: 0,
            transform: "translate3d(0,100%,0)"
        },

        to: {
            opacity: 1,
            transform: "none"
        }
    },

    fadeInUp: {
        animationName: "$fadeInUp"
    },

    "@keyframes lightSpeedIn": {
        from: {
            transform: "translate3d(100%,0,0) skewX(-30deg)",
            opacity: 0
        },

        "60%": {
            transform: "skewX(20deg)",
            opacity: 1
        },

        "80%": {
            transform: "skewX(-5deg)",
            opacity: 1
        },

        to: {
            transform: "none",
            opacity: 1
        }
    },

    lightSpeedIn: {
        animationName: "$lightSpeedIn",
        animationTimingFunction: "ease-out"
    },

    "@keyframes rotateIn": {
        from: {
            transformOrigin: "center",
            transform: "rotate3d(0,0,1,-200deg)",
            opacity: 0
        },

        to: {
            transformOrigin: "center",
            transform: "none",
            opacity: 1
        }
    },

    rotateIn: {
        animationName: "$rotateIn"
    },

    "@keyframes rotateInDownLeft": {
        from: {
            transformOrigin: "left bottom",
            transform: "rotate3d(0,0,1,-45deg)",
            opacity: 0
        },

        to: {
            transformOrigin: "left bottom",
            transform: "none",
            opacity: 1
        }
    },

    rotateInDownLeft: {
        animationName: "$rotateInDownLeft"
    },

    "@keyframes rotateInDownRight": {
        from: {
            transformOrigin: "right bottom",
            transform: "rotate3d(0,0,1,45deg)",
            opacity: 0
        },

        to: {
            transformOrigin: "right bottom",
            transform: "none",
            opacity: 1
        }
    },

    rotateInDownRight: {
        animationName: "$rotateInDownRight"
    },

    "@keyframes rotateInUpLeft": {
        from: {
            transformOrigin: "left bottom",
            transform: "rotate3d(0,0,1,45deg)",
            opacity: 0
        },
        to: {
            transformOrigin: "left bottom",
            transform: "none",
            opacity: 1
        }
    },

    rotateInUpLeft: {
        animationName: "$rotateInUpLeft"
    },

    "@keyframes rotateInUpRight": {
        from: {
            transformOrigin: "right bottom",
            transform: "rotate3d(0,0,1,-90deg)",
            opacity: 0
        },

        to: {
            transformOrigin: "right bottom",
            transform: "none",
            opacity: 1
        }
    },

    rotateInUpRight: {
        animationName: "$rotateInUpRight"
    },

    "@keyframes rollIn": {
        from: {
            opacity: 0,
            transform: "translate3d(-100%,0,0) rotate3d(0,0,1,-120deg)"
        },

        to: {
            opacity: 1,
            transform: "none"
        }
    },

    rollIn: {
        animationName: "$rollIn"
    },

    "@keyframes zoomIn": {
        from: {
            opacity: 0,
            transform: "scale3d(.3,.3,.3)"
        },

        "50%": {
            opacity: 1
        }
    },

    zoomIn: {
        animationName: "$zoomIn"
    },

    "@keyframes zoomInDown": {
        from: {
            opacity: 0,
            transform: "scale3d(.1,.1,.1) translate3d(0,-1000px,0)",
            animationTimingFunction: "cubic-bezier(.55,.055,.675,.19)"
        },

        "60%": {
            opacity: 1,
            transform: "scale3d(.475,.475,.475) translate3d(0,60px,0)",
            animationTimingFunction: "cubic-bezier(.175,.885,.32,1)"
        }
    },

    zoomInDown: {
        animationName: "$zoomInDown"
    },

    "@keyframes zoomInLeft": {
        from: {
            opacity: 0,
            transform: "scale3d(.1,.1,.1) translate3d(-1000px,0,0)",
            animationTimingFunction: "cubic-bezier(.55,.055,.675,.19)"
        },
        "60%": {
            opacity: 1,
            transform: "scale3d(.475,.475,.475) translate3d(10px,0,0)",
            animationTimingFunction: "cubic-bezier(.175,.885,.32,1)"
        }
    },

    zoomInLeft: {
        animationName: "$zoomInLeft"
    },

    "@keyframes zoomInRight": {
        from: {
            opacity: 0,
            transform: "scale3d(.1,.1,.1) translate3d(1000px,0,0)",
            animationTimingFunction: "cubic-bezier(.55,.055,.675,.19)"
        },

        "60%": {
            opacity: 1,
            transform: "scale3d(.475,.475,.475) translate3d(-10px,0,0)",
            animationTimingFunction: "cubic-bezier(.175,.885,.32,1)"
        }
    },

    zoomInRight: {
        animationName: "$zoomInRight"
    },

    "@keyframes zoomInUp": {
        from: {
            opacity: 0,
            transform: "scale3d(.1,.1,.1) translate3d(0,1000px,0)",
            animationTimingFunction: "cubic-bezier(.55,.055,.675,.19)"
        },

        "60%": {
            opacity: 1,
            transform: "scale3d(.475,.475,.475) translate3d(0,-60px,0)",
            animationTimingFunction: "cubic-bezier(.175,.885,.32,1)"
        }
    },

    zoomInUp: {
        animationName: "$zoomInUp"
    }

}))