

import React, {  } from 'react';
import { Box } from "@material-ui/core";
import { colors } from "../theme";
import EmptyIcon from '../img/EmptyIcon';

interface IEmptyDataProps {

    children?: React.ReactNode;
}

export const EmptyData: React.FC<IEmptyDataProps> = () => {
    return (

            <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
                <h3>Momentálně nemáme co zobrazit</h3>
                <EmptyIcon height={250} fill={colors.common.fadedGreen} />
            </Box>

    )
}
